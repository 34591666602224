import React,{useState, useEffect} from 'react'
import AOS from 'aos';
// import testImage from "../Images/home/footer-bg-img"
import 'aos/dist/aos.css';
import axios from "axios"
import ServiceCall from "../ServiceCall"
import {Modal,Button} from "react-bootstrap"
import BlogPost from './BlogPost';
import backgroundimg from "../Images/banner-bg.jpg"
import { useParams, useNavigate } from 'react-router-dom';

function Blogs() {

    const [isArticalData, setArticalData] = useState("");
    const [isIframe, setIsIframe] = useState(false);
    const [Url, setUrl] = useState("");
    const [show, setShow] = useState(false);
    const [blogpost, setBlogpost] = useState(false)
    const [blogData, setBlogData] = useState()
    const[loading, stillLoading] = useState(true)
    const {slug} = useParams();

   const navigate = useNavigate();


    useEffect(()=>{
        ArticalData();
    },[])
    useEffect(()=>{
        AOS.init({duration:1000})
        // i use AOS library for animaton 
    })

    // const finishLoading = () => {
    //     stillLoading(false)
    // }

    // const handleClose = () => {
    //     setShow(false)
    //     setIsIframe(false)
    // };

    // const handleShow = () => {
    //     setShow(true)
    // };
   
    
    const navigation = (items) => {
    //  setIsIframe(true)
    // handleShow()
    // setUrl(e)
    navigate(`/blogs/${items.slug}`)
        setBlogpost(true)
    setBlogData(items)

   }

    const ArticalData = async () =>{
        await axios.get(`https://public-api.wordpress.com/rest/v1.1/sites/rxoneblog.wordpress.com/posts?slug=${slug}`).then(res=>setArticalData(res.data.posts))
       return isArticalData;
    }

    
   function truncate(string,max){
     return string.length>max ? string.substr(0,max-1)+"...": string;
   }

   
   return (
    <> 
       <img
                           src={backgroundimg}
                           style={{
                             width: "100%",
                             height: "200px",
                             position: "absolute",
                             top: 0,
                             left: 0,
                             zIndex: -1, // To place it behind other content
                             backgroundRepeat: "no-repeat",
                             backgroundPosition: "center",
                             backgroundSize: "cover",
                           }}
                           alt="Background"
                         />
     <div style={{marginTop:"0px", marginBottom:"80px"}} >
    
    {blogpost &&

     <BlogPost close={setBlogpost} allBlog={isArticalData} setblog={setBlogData} blog={blogData} />
    }

    
 { !blogpost && 
 <>
     <section className="ms-about-banner" style={{marginBottom: "55px", backgroundColor:"rgb(0, 145, 133)"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 style={{color:"#fff"}} className="ms-page-header-title">Blog</h1>
                    </div>
                </div>
            </div>
        </section>
    
    {   isArticalData.length === 0 ?
                 <div style={{width:"100%"}} className="d-flex justify-content-center align-items-center ">
                      <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                            </div> 
                      </div>:
           <div style={{gap:"20px", width:"80%"}} className='d-flex flex-wrap justify-content-center m-auto'>
    { isArticalData.map(items => (
      <div key={items.ID}>
            <div className="card light blog rounded" style={{width: "22rem", height:"20rem",background:` ${ items.post_thumbnail==null?"../Images/home/footer-bg-img.png" :"url"+"("+ items.post_thumbnail.URL +")"}`, backgroundSize:"cover"}}>
            <div className="card-body" style={{background: "linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3))"}} >
                <h5 style={{color: "white"}} className="card-title light fw-bolder">{items.title}</h5>
                {/* <p style={{color: "white"}} className="card-text light">{truncate(items.content.replace("<p>", " ").replace("</p>", ""),180)} </p> */}
                
                <div  onClick={()=>navigation(items)} style={{cursor:"pointer", zIndex: "0"}} className="ms-category-meta"> <a>Read Now</a> </div> 
            </div>
            </div>
      </div>
     )) 
     
     }
           </div>
    }
 </>
 }

</div>
</>
  )
}

export default Blogs