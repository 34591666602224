import React,{useEffect, useState} from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import abouticon1 from "../Images/about/Company Overview.svg"
import image__1 from "../Images/about/doctor-online-online-medical-communication-network-with-patient-online-medical-consultation.jpg"
import image__2 from "../Images/about/core-values-word-young-people.jpg"
import nitinGuru from "../Images/doctorsImages/Nitin Guru.jpg"
import prashant from "../Images/doctorsImages/Prashant Mishra.jpg"
import himaniNarula from "../Images/doctorsImages/Dr.HimaniNarula.jpg"
import shrutiGuru from "../Images/doctorsImages/Shruti Guru.jpg"
import parsoonPathak from "../Images/doctorsImages/Dr.PrasoonPathak.jpg"
import "../Css/about.css"
import ContectUs from './ContectUs';
function AboutUs() {

useEffect(()=>{
    AOS.init({duration:1000})
})

  return (
      <>
          
        {/* <!-- Banner Section --> */}
        <section className="ms-about-banner"style={{backgroundColor:"rgb(0, 145, 133)"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 style={{color:"#fff"}} className="ms-page-header-title">About Us</h1>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Content Section --> */}
        <section className="ms-content-section">
            <div className="container">
                <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
                    <div data-aos="slide-right" className="col-lg-6">
                    <div className='content__logo'><img src={abouticon1} alt="content-logo" /></div>
                        <h3 className="mb-4 ms-font-weight-600 ms-primary-color">Company Overview</h3>
                        <h6 className="fw-normal">At RxOne (Rx One Care), we aim to revolutionize healthcare ecosystem by providing advance technology solutions to ease out Patient-Physician-Provider connectivity. Providing a unique platform, we aim to personalize Provider's and Doctor's engagement with their Customers (Patients), while preserving a state of the art experience for Patients</h6>
                        <br />
                        <h6 className="fw-normal">RxOne intends to unify the experience for Patients, so they don’t need to worry about going to different platforms and interfaces for their care needs. At background it uses an innovative Health Data Exchange engine that gives a seamless experience to users whether they take services from one provider or multiple.</h6>
                    </div>
                    <div data-aos="slide-left" className="col-lg-6">
                        <img style={{width:"500px"}} src={image__1} className="img-fluid ms-border-rounded" alt="Image"/>
                    </div>
                </div>
                <div className="row align-items-center margin__second g-5">
                    <div data-aos="slide-right" data-aos-duration="500" className="col-lg-6">
                        <img style={{width:"500px"}} src={image__2}  className="img-fluid ms-border-rounded" alt="Image"/>
                    </div>
                    <div data-aos="slide-left" className="col-lg-6">
                        <h3 className="ms-font-weight-600 mb-4 ms-primary-color">Our Core Values</h3>
                        <div className="d-flex mb-3">
                            <i className="fas fa-check-circle ms-check-icon"></i>
                            <h6 className="ms-2 mb-0 fw-normal">Being Thankful -  We value and humbled by the responsibility and opportunity to make a difference to People's lives.</h6>
                        </div>
                        <div className="d-flex mb-3">
                            <i className="fas fa-check-circle ms-check-icon"></i>
                            <h6 className="ms-2 mb-0 fw-normal">Accountability - We take pride in being accountable for products we offer and services we provide. We proactively seek opportunities for improvement and try to be better than ourselves.</h6>
                        </div>
                        <div className="d-flex mb-3">
                            <i className="fas fa-check-circle ms-check-icon"></i>
                            <h6 className="ms-2 mb-0 fw-normal">Integrity - We reward owning honest mistakes and zeal to make things better. We respect continuous pursuit towards mitigating risks for our stakeholders and customers.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* <!-- Team Section  --> */}
        {/* <section data-aos="slide-up" className="ms-team-section">
            <div className="container">
                <div className="row mb-3">
                    <div className="col-md-12 text-center">
                        <h3 className="ms-font-weight-600 mb-4 ms-primary-color">Leadership Team</h3>
                    </div>
                </div>
                <div className="row">
                    <div data-aos="slide-up" className="col-md-2 ms-4 me-3">
                        <div className="ms-team-box text-center">
                            <img src={nitinGuru} className="img-fluid mb-3" alt="Image"/>
                            <h5 className="ms-font-weight-600">Nitin Guru</h5>
                            <h6>Technology Advisor</h6>
                            <p>17+ Years Exp, Data Analytics & Digital Transformation Leader</p>
                        </div>
                    </div>
                    <div data-aos="slide-up" className="col-md-2 ms-4 me-3">
                        <div className="ms-team-box text-center">
                            <img src={himaniNarula} className="img-fluid mb-3" alt="Image"/>
                            <h5 className="ms-font-weight-600">Dr. Himani Narula</h5>
                            <h6>Subject Matter Expert</h6>
                            <p>19+ Years Exp, Developmental Behavioural Paediatrician</p>
                        </div>
                    </div>
                    <div data-aos="slide-up" className="col-md-2 ms-4 me-3">
                        <div className="ms-team-box text-center">
                            <img src={shrutiGuru} className="img-fluid mb-3" alt="Image"/>
                            <h5 className="ms-font-weight-600">Shruti Guru</h5>
                            <h6>Founder & CEO</h6>
                            <p>9+ Years Exp, Human Resource Management and People Leadership</p>
                        </div>
                    </div>
                    <div data-aos="slide-up" className="col-md-2 ms-4 me-3">
                        <div className="ms-team-box text-center">
                            <img src={parsoonPathak} className="img-fluid mb-3" alt="Image"/>
                            <h5 className="ms-font-weight-600">Dr. Prasoon Pathak</h5>
                            <h6>Advisor</h6>
                            <p>15+ Years Exp, Critical Care Specialist</p>
                        </div>
                    </div>
                    <div data-aos="slide-up" className="col-md-2 ms-4 me-3">
                        <div className="ms-team-box text-center">
                            <img src={prashant} className="img-fluid mb-3" alt="Image"/>
                            <h5 className="ms-font-weight-600"> Prashant Mishra</h5>
                            <h6>Co-Founder</h6>
                            <p>25+ Years Exp, Lifesciences and Medical Technology Leader</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

{/* *------------------------ Contect US -------------------------* */}

        <ContectUs/>

      </>
  );
}

export default AboutUs;
