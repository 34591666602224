import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Css/home.css";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import logo from "../Images/SVG/fav icon.svg";
import Image1 from "../Images/33.png";
import BlogPost from "./BlogPost";
import testImage from "../Images/bnr-img.png";
import bannerImg2 from "../Images/home/slider img.png";
import bannerImg1 from "../Images/provider/man-girl-doctor-are-smiling-together.png";
import bannerImg3 from "../Images/44.png";
import homeIcon1 from "../Images/icons/rx one icons (5).svg";
import homeIcon2 from "../Images/icons/rx one icons (2).svg";
import homeIcon3 from "../Images/icons/rx one icons (3).svg";
import Appointment from "../Images/Check_1.gif";
import Medicine from "../Images/Heart_beat_1.gif";
import Lab from "../Images/Medicine_book_1.gif";
import connect from "../Images/screen.png";
import Image2 from "../Images/home/tab-mockup.png";
import Image3 from "../Images/home/background-img.png";
import Blog__1 from "../Images/blog-1.jpg";
import himaniNarula from "../Images/doctorsImages/Dr.HimaniNarula.jpg";
import parsoonPathak from "../Images/doctorsImages/Dr.PrasoonPathak.jpg";
import Boy from "../Images/boy.jpg";
import Blog__2 from "../Images/blog-2.jpg";
import Girl from "../Images/girl.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import { Carousel } from "react-bootstrap";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ServiceCall from "../ServiceCall";
import ladypic from "../Images/Lady.png";
import drkamal from "../Images/johny.png";
import drjp from "../Images/tony.jpg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import statsappoint from "../Images/care.png";
import Treatment from "../Images/Treatments.png";
import Confuse from "../Images/confusedboy.gif";
import aboy from "../Images/looking-around-loop-ezgif.com-gif-maker.gif";
import doctorwrite from "../Images/doctorwrite.gif";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import consultpic from "../Images/consult.png";
import { useMediaQuery } from "@material-ui/core";
import phoneimg from "../Images/home/RxOne-App-Mockup.png";
import apple from "../Images/home/apple1.jpeg";
import android1 from "../Images/home/Android.png";
import fortis from "../Images/home/Fortis_Healthcare_Logo.png";
import amrita from "../Images/home/amrita-hos.png";
import birla from "../Images/home/bif2.png";
import asian from "../Images/home/asian.png";
import shahdry from "../Images/home/sahyadri-logo.png";
import Metro from "../Images/home/metro.png";
import prayag from "../Images/home/prayag.png";
import crysta from "../Images/home/crysta.png";
import artimi from "../Images/home/artimi.png";
import Marengo from "../Images/home/mar2.png";
import glenagles from "../Images/home/glenagles.png";
import yatharth from "../Images/home/Yatharth.png";
import motherland from "../Images/home/motherland.png";
import bima from "../Images/home/bimacare.png"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Customdetails } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import cure1 from "../Images/home/h21.png";
import cure2 from "../Images/home/Untitled design.png";
import track from "../Images/home/h23.png";
import track1 from "../Images/home/h24.png";
import track2 from "../Images/home/h25.png";
import healthtrend from "../Images/home/h26.png";
import curephone from "../Images/home/curephone.png";
import t1 from "../Images/home/t1.png";
import t2 from "../Images/home/t2.png";
import t3 from "../Images/home/t3.png";
import t4 from "../Images/home/t4.png";
import t5 from "../Images/home/t5.png";
import t6 from "../Images/home/t6.png";
import startup from "../Images/home/startup.png";
import hatron from "../Images/home/hartron.png";
import nasscom from "../Images/home/nasscom.jpg";
import Treatmentmini from "./Treatmentmini";
import Honor from "./Honor"; // Adjust the path as per your folder structure

const CounterItem = ({ end, label }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const isMobile = useMediaQuery("(max-width:710px)");

  return (
    <div ref={ref} className="col-md-3 text-center">
      <h3 className="ms-font-weight-600 --black">
        {inView ? (
          <CountUp start={1} end={end} duration={2.5} separator="," />
        ) : (
          "0"
        )}
        <span>+</span>
      </h3>
      <h4
        className="ms-font-weight-600 ms-third-color"
        style={{
          textAlign: "center",
          marginBottom: isMobile ? "4rem" : "2rem",
        }}
      >
        {label}
      </h4>
    </div>
  );
};

function Home1() {
  const bannerImages = [
    fortis,
    amrita,
    birla,
    asian,
    shahdry,
    Marengo,
    yatharth,
    bima,
    glenagles,
    motherland,
    artimi,
    Metro,
    crysta,
    prayag,
    
  ];
  const bannerImage = [hatron, nasscom, startup];
  const isMobile = useMediaQuery("(max-width:710px)");

  const [index, setIndex] = useState(0);
  const [isArticalData, setArticalData] = useState([]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [show, setShow] = useState(false);
  const [blogpost, setBlogpost] = useState(false);
  const [blogData, setBlogData] = useState();
  const [isIframe, setIsIframe] = useState(false);
  const [Url, setUrl] = useState("");
  const [cdata, setCdata] = useState([]);
  const [isStuck, setIsStuck] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const headings = [
    "Care to Cure - One App for All Care Needs",
    "Consult with Top Specialists",
    "Securely manage health records",
    "Discover Care Path ",
  ];

  const sections = [
    {
      id: "discover",
      title: "Discover Cure Ways",
      content: "Find different treatments suitable for your needs",
      icon: t1,
      image: cure1,
    },
    {
      id: "connect",
      title: "Connect with Experts",
      content: "Seek advice or second opinion from top specialists",
      icon: t2,
      image: cure2,
    },
    {
      id: "track",
      title: "Manage Records",
      content: "Safely manage health records for self & family",
      icon: t3,
      image: track,
    },
    {
      id: "reminders",
      title: "Track Vitals",
      content: "Track critical vitals & share with experts for diagnosis",
      icon: t4,
      image: track1,
    },
    {
      id: "reminders",
      title: "Never miss a Dose",
      content: "Get automated reminders for prescribed medicines",
      icon: t5,
      image: track2,
    },
    {
      id: "reminders",
      title: "Health Trends",
      content:
        "Get the latest information & trends important to improve health.",
      icon: t6,
      image: healthtrend,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const sectionRefs = useRef(sections.map(() => React.createRef()));
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerTop = containerRect.top;

      for (let i = 0; i < sectionRefs.current.length; i++) {
        const section = sectionRefs.current[i].current;
        if (!section) continue;

        const sectionRect = section.getBoundingClientRect();
        const sectionTop = sectionRect.top - containerTop;

        if (sectionTop <= 0 && sectionTop > -sectionRect.height) {
          setActiveIndex(i);
          break;
        }
      }
    };
    const scrollableContent = containerRef.current;
    if (scrollableContent) {
      scrollableContent.addEventListener("scroll", handleScroll);
      return () =>
        scrollableContent.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const MobileLayout = () => (
    <div className="container">
      {sections.map((section, index) => (
        <div
          key={section.id + index}
          className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row"
        >
          <div className="col-lg-6">
            <img
              style={{ width: "500px", marginTop: "-3rem" }}
              src={section.image}
              className="img-fluid ms-border-rounded"
              alt={section.title}
            />
          </div>
          <div className="col-lg-6">
            <div className="content__logo">
              <img src={section.icon} alt="content-logo" />
            </div>
            <h3 className="mb-4 ms-font-weight-600 ms-primary-color">
              {section.title}
            </h3>
            <h2
              className="ms-font-weight-500 ms-primary-color"
              style={{ fontSize: "2rem" }}
            >
              {section.content}
            </h2>
          </div>
        </div>
      ))}
    </div>
  );
  // const [swap, setSwap] = useState("")
  /**** ***** THESE TWO ARE FOR I FRAME ******** */
  const [loading, stillLoading] = useState(true);
  /**** ***** THESE TWO ARE FOR I FRAME ******** */
  const carouselRef = useRef(null);

  const [nav, isNav] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setIsStuck(false); // Set isStuck to false to remove the video
  };

  const handleMoveLeft = () => {
    carouselRef.current.prev();
  };

  const handleMoveRight = () => {
    carouselRef.current.next();
  };

  const transitionNav = () => {
    if (window.scrollY > 600 && window.scrollY < 3200) {
      isNav(true);
    } else {
      isNav(false);
    }
  };

  function truncate(string, max) {
    return string.length > max ? string.substr(0, max - 1) + "..." : string;
  }
  const navigation = (items) => {
    //  setIsIframe(true)
    // handleShow()
    // setUrl(e)
    navigate(`/blogs/${items.slug}`);
    setBlogpost(true);
    setBlogData(items);
  };

  const navigateTop = () => {
    window.scrollTo(0, 0);
  };

  // ***************** Fetching the Artical Section Data ******************************
  useEffect(() => {
    window.addEventListener("scroll", transitionNav);
    return () => window.addEventListener("scroll", transitionNav);
  });

  useEffect(() => {
    ArticalData();
    AOS.init({ duration: 1000 });
    // i use AOS library for animaton
  }, []);

  // console.log(isArticalData)

  useEffect(() => {
    const carddata = async () => {
      try {
        const cards = await ServiceCall.gett("/rxone/web/cure/ways");

        if (cards) {
          setCdata(cards.data.cure_ways_list);
        }
      } catch (err) {}
    };
    carddata();
  }, []);

  const ArticalData = async () => {
    await axios
      .get(
        "https://public-api.wordpress.com/rest/v1.1/sites/rxoneblog.wordpress.com/posts"
      )
      .then((res) => setArticalData(res.data.posts));
    return isArticalData;
  };

  const handleButtonClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN",
      "_blank"
    );
  };

  const handleAppleClick = () => {
    window.open(
      "https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685",
      "_blank"
    );
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const windowScrollTop = window.scrollY;
  //     const $videoWrap = document.querySelector(".video-wrap");
  //     const $video = document.querySelector(".video");
  //     const videoHeight = $video.offsetHeight;
  //     const videoBottom = videoHeight + $videoWrap.offsetTop;

  //     if (windowScrollTop > videoBottom) {
  //       $videoWrap.style.height = `${videoHeight}px`;
  //       $video.classList.add("stuck");
  //       setIsStuck(true);
  //     } else {
  //       $videoWrap.style.height = "auto";
  //       $video.classList.remove("stuck");
  //       setIsStuck(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const curepath = (item) => {
    const specialty = item.title; // Store specialty in a variable
    navigate(`/cureways/${item.code}`, {
      state: { specialty },
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStuck(true);
    }, 5000); // Set the duration to 5 seconds (5000 milliseconds)

    // Clear the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleID = () => {
    window.open(
      "https://booking.setmore.com/scheduleappointment/e0da9b0f-e5cc-4f40-b54a-c65075466234"
    );
  };

  // const handleRemoveVideo = () => {
  //   const videoWrapper = document.getElementById("video-wrapper");
  //   if (videoWrapper) {
  //     videoWrapper.remove();
  //     // setIsStuck(false);
  //   }
  // };

  const handleregis = () => {
    navigate("/treatment-advice");
  };

  const handlclick = () => {
    window.open("http://www.tosto.re/RxOne");
  };

  const handlclicks = (item) => {
    if (item.title === "Consult Now") {
      navigate("/consultation");
    } else if (item.title === "Seek Advice") {
      navigate("/treatment-advice");
    } else if (item.title === "Order Now") {
      navigate("/order-medicine-online-india");
    } else if (item.title === "Book Now") {
      navigate("/book-pathology-tests-india");
    } else if (item.title === "Know More") {
      navigate("/medical-finance-loan-india");
    }
  };

  const counterData = [
    { end: 500, label: "Care Providers" },
    { end: 11000, label: "Happy Customers" },
    { end: 15000, label: "Body Vitals" },
    { end: 20000, label: "Digital Records" },
  ];
  return (
    <div>
      {/* ******************** artical iframe *******************************  */}

      {/* ******************** article iframe *******************************  */}

      {/* <!-- Banner Section --> */}

      {blogpost ? (
        <BlogPost
          close={() => setBlogpost(false)}
          allBlog={isArticalData}
          setblog={setBlogData}
          blog={blogData}
        />
      ) : (
        <>
          <div>
            {/* <div id="video-wrapper" className="video-wrap stuck">
              <div className="video">
                <iframe
                  title="RxOne Demo Video"
                  width="240"
                  height="135"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 6px 14px rgba(5, 8, 7, 3.1)",
                  }}
                  src="https://www.youtube.com/embed/J53wLhoAn6I?si=XqXKU3x--GbxjPLT&autoplay=1&loop"
                  frameBorder="0"
                  gesture="media"
                  allowFullScreen
                ></iframe>
                <button
                  className="cross-button"
                  onClick={handleRemoveVideo}
                  style={{
                    position: "absolute",
                    top: "-18px",
                    right: "-7px",
                    zIndex: 999,
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#005c74")}
                >
                  <CancelOutlinedIcon
                    style={{ color: "#fff", cursor: "pointer" }}
                  />
                </button>
              </div>
            </div> */}
          </div>

          <div
            onClick={navigateTop}
            id="navigate_top"
            className={`home__up__navigation d-flex justify-content-center ${
              !nav && "d-none"
            }`}
          >
            <div className="home_up_arrow d-flex justify-content-center align-items-center ">
              <a herf="#">
                <ArrowUpwardOutlinedIcon
                  sx={{ fontSize: 30, color: "white" }}
                />
              </a>
            </div>
          </div>
          <section className="care-banner">
  <div className="container care-container">
    <div className="row center-content">
      <div className="col-md-12 text-center">
        <h1 className="care-heading">Your Care to Cure Companion</h1>
      </div>
      <div className="col-md-12 text-center care-download">
        <div className="care-download-btn" onClick={handlclick}>
          <h3 className="care-download-text">
            Download App
            <img src={apple} alt="Apple" className="care-download-icon" />
            <img src={android1} alt="Android" className="care-download-icon" />
          </h3>
        </div>
      </div>
    </div>
    <div className="row center-content">
      <div className="care-swiper">
        <div className="care-swiper-container">
          <div className="care-image hover-effect">
            <img src={phoneimg} alt="Phone" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


          {/* ---key partners---- */}
         

          {/* <!-- Stand Apart Section --> */}
          <section data-aos-duration="3000" className="ms-apart-section">
            <div data-aos="fade-up" className="container">
              <div className="grid row g-4"style={{marginTop:"2rem"}}>
                {counterData.map((item, index) => (
                  <CounterItem key={index} end={item.end} label={item.label} />
                ))}
              </div>
              <section
            id="care-paths-section"
            data-aos-duration="3000"
            className="ms-cured-section"
          >
            <div data-aos="fade-up" className="container">
              <h2
                className="honor-heading"
                style={{ textAlign: "center", marginBottom: "5rem", marginTop:"0px" }}
              >
                Key Partners
              </h2>

              {bannerImages && Array.isArray(bannerImages) && (
                <OwlCarousel
                  className="owl-theme"
                  autoplay={true}
                  autoplayTimeout={1200}
                  smartSpeed={800}
                  loop={true}
                  margin={20}
                  dots={false}
                  responsive={{
                    0: {
                      items: 2, // Show 1 item on very small screens
                    },
                    480: {
                      items: 2, // Show 2 items on slightly larger screens
                    },
                    768: {
                      items: 3, // Show 3 items on tablet and larger screens
                    },
                  }}
                >
                  {bannerImages.map((bannerImg, index) => (
                    <div key={index} className="itemss">
                      <div className="customImg">
                        <img
                          src={bannerImg}
                          alt={`Slide ${index}`}
                          //   style={{ height: "200px", width: "200px", objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </section>
              <section className="honor-section"style={{marginBottom:"1.5rem"}}>
        
        <Honor />
      </section>
              <Treatmentmini/>
              <div data-aos="slide-up" className="container">
                <div className="row mb-5"></div>
              </div>
              <div
                style={{
                  margin: "-103px",
                  background: "#fff",
                  borderRadius: "15px",
                  padding: "28px 19px",
                }}
              >
                <div
                  style={{
                    gridGap: "3.5rem",
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr", // Default for large screens
                    overflow: "hidden",
                  }}
                  className="responsive-grid" // Adding class to apply responsive styles
                >
                  {Customdetails?.map((item, index) => (
                    <div className="provider__revolutionize__item" key={index}>
                      <div className="provider__revolutionize__icon">
                        <img src={item?.icon} alt={item?.alt} />
                      </div>
                      <h6 className="provider__revolutionize__text ms-2 mb-0 fw-normal text-center">
                        {item?.text}
                      </h6>
                      <h4
                        className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 "
                        style={{
                          border: "1px solid",
                          padding: "4px 8px",
                          borderRadius: "15px",
                          cursor: "pointer",
                          backgroundColor: "#005c74",
                          color: "#fff",
                        }}
                        onClick={() => handlclicks(item)}
                      >
                        {item?.title}
                      </h4>
                    </div>
                  ))}
                </div>

                {/* Media queries for responsiveness */}
                <style jsx>{`
                  @media (max-width: 768px) {
                    .responsive-grid {
                      gridtemplatecolumns: 1fr 1fr; /* Show two cards per row on tablets */
                    }
                  }
                  @media (max-width: 576px) {
                    .responsive-grid {
                      gridtemplatecolumns: 1fr; /* Show one card per row on mobile */
                    }
                  }
                `}</style>
              </div>
            </div>
          </section>
        
          <section data-aos-duration="3000" className="ms-cureways-section">
            <div className="container" style={{ marginTop: "-8rem" }}>
              <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
                <div className="col-lg-6">
                  <h1
                    className="mb-4 ms-font-weight-600"
                    style={{ color: "#fff" }}
                  >
                    Customer Stories
                  </h1>
                </div>
              </div>
            </div>
            <div className="container" style={{ marginTop: "-10rem" }}>
              <div className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row">
                <div className="col-lg-6">
                  <h2
                    className="ms-font-weight-500"
                    style={{
                      color: "#fff",
                      fontSize: isMobile ? "1.5rem" : "3.5rem",
                    }}
                  >
                    More than 10,000 Customers trust RxOne for various care
                    services offered
                  </h2>
                  <div className="care-download-btn" onClick={handlclick}>
          <h3 className="care-download-text">
            Download App
            <img src={apple} alt="Apple" className="care-download-icon" />
            <img src={android1} alt="Android" className="care-download-icon" />
          </h3>
        </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                      maxWidth: "100%",
                      borderRadius: "15px",
                      boxShadow:
                        "0 25px 25px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "15px",
                      }}
                      src="https://www.youtube.com/embed/DkHcPuxMhzU?autoplay=1&mute=1&loop=1&playlist=DkHcPuxMhzU"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* phone scrolling */}
          <section className="ms-content-section bg-white">
          <div className="container">
      {sections.map((section, index) => (
        <div
          key={section.id + index}
          className="row align-items-center mb-5 g-5 flex-column-reverse margin__first flex-lg-row"
        >
          <div className="col-lg-6">
            <img
             style={{
              width: "500px",
              marginTop: window.innerWidth <= 768 ? "-3rem" : "-10rem", // Adjust based on screen size
            }}
              src={section.image}
              className="img-fluid ms-border-rounded"
              alt={section.title}
            />
          </div>
          <div className="col-lg-6">
            <div className="content__logo">
              <img src={section.icon} alt="content-logo" />
            </div>
            <h3 className="mb-4 ms-font-weight-600 ms-primary-color">
              {section.title}
            </h3>
            <h2
              className="ms-font-weight-500 ms-primary-color"
              style={{ fontSize: "2rem" }}
            >
              {section.content}
            </h2>
            <br></br>
            {/* Replacing Button with Styled Div */}
            <div
                    className="download-button" // Add a class to handle responsive styling
                    onClick={handlclick}
                    style={{ borderRadius: "12px" , borderColor:"#01A09E", color:"#fff" ,backgroundColor:"rgb(0, 145, 133)"}}
                  >
                    <h3
                      className="ms-font-weight-600"
                      style={{
                        color: "#fff",
                        margin: 0,
                        cursor: "pointer",
                        fontSize: "22px",
                      }}
                    >
                      Download App
                     
                    </h3>
                  </div>
          </div>
        </div>
      ))}
    </div>
          </section>

          <section
            id="care-paths-section"
            //   data-aos-duration="3000"
            className="ms-cured-section"
          >
            <div
              // data-aos="fade-up"
              className="container"
            >
              {bannerImage && Array.isArray(bannerImage) && (
                <OwlCarousel
                  className="owl-theme"
                  autoplay={true}
                  autoplayTimeout={1200}
                  smartSpeed={800}
                  loop={true}
                  margin={20} // Reduce margin for mobile
                  dots={false}
                  responsive={{
                    0: {
                      items: 1, // Show 1 item on very small screens
                    },
                    480: {
                      items: 2, // Show 2 items on slightly larger screens
                    },
                    768: {
                      items: 3, // Show 3 items on tablet and larger screens
                    },
                  }}
                >
                  {bannerImage.map((bannerImg, index) => (
                    <div key={index} className="items">
                      <div className="customImg">
                        <img
                          src={bannerImg}
                          alt={`Slide ${index}`}
                          //   style={{ height: "200px", width: "200px", objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </div>
          </section>
          {/* <!-- Top Articles Section --> */}

          <section className="ms-article-section">
            <div data-aos="fade-up" className="container">
              <div className="row align-items-center">
                <div className="col-lg-9">
                  <h3 className="ms-font-weight-600 ms-primary-color">Blog</h3>
                  <p className="fw-normal ms-third-color mb-3 mb-lg-0">
                    Articles & Videos by the health experts, that keep you
                    informed about good health practices and latest development
                    in health-care.
                  </p>
                </div>
                <div className="col-lg-3 text-start text-lg-end">
                  <Link to="/blogs" className="ms-secondary-btn">
                    View All <i className="fas fa-chevron-right ms-2"></i>
                  </Link>
                </div>
              </div>
              <div className="row mt-4">
                {isArticalData.length === 0 ? (
                  <div
                    style={{ width: "100%" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  isArticalData.slice(0, 2).map((res, item) => (
                    <div
                      key={item}
                      data-aos="fade-up"
                      className="col-md-6 col-lg-4"
                    >
                      <article className="ms-article-box">
                        <div className="ms-featured-img position-relative overflow-hidden">
                          {res.post_thumbnail == null ? (
                            <img
                              src={testImage}
                              className="img-fluid"
                              alt="Article Image"
                            />
                          ) : (
                            <img
                              src={res.post_thumbnail.URL}
                              className="img-fluid"
                              alt="Article Image"
                            />
                          )}
                          <div className="ms-category-meta">
                            <a href="#" onClick={() => navigation(res)}>
                              {
                                res.categories[Object.keys(res.categories)[0]]
                                  .name
                              }
                            </a>
                          </div>
                        </div>
                        <div className="ms-article-content bg-white">
                          <a className="ms-primary-color" href="#.">
                            <h5 className="ms-font-weight-600">{res.title}</h5>
                          </a>
                          <div className="ms-author-meta my-3">
                            <img src={logo} className="img-fluid" alt="Image" />
                            <p className="mb-0 d-inline ms-third-color">
                              {res.author.first_name}
                            </p>
                          </div>
                          <hr className="ms-third-color" />
                          <div className="ms-description">
                            <p>
                              {truncate(
                                res.excerpt
                                  .replace("<p>", " ")
                                  .replace("</p>", ""),
                                180
                              )}
                            </p>
                            <p onClick={() => navigation(res)}>
                              <a className="ms-primary-color position-relative">
                                Read more
                              </a>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default Home1;
