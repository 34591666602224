import React from "react";
import { Link } from "react-router-dom";
import footer__logo from "../Images/SVG/footer logo.svg";
import "../Css/footer.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <>
      <footer className="ms-main-footer"style={{backgroundColor:"#009185"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <a href="#.">
                <img
                  src={footer__logo}
                  className="footer__img img-fluid"
                  width="200"
                  alt="Footer Logo"
                />
              </a>
              <p className="text-white mt-4">
                Building Simple, Easy to Use and Effective Digital Health
                solutions for users, to solve care accessibility challenge in
                India and Worldwide.
              </p>
              <div className="ms-social-links mb-4 mb-lg-0">
                <a href="https://www.facebook.com/profile.php?id=100089472039715">
                  {/* <i className="fab fa-facebook-f ms-icon"></i> */}
                  <FacebookIcon />
                </a>
                <a href="https://twitter.com/RxOneCare">
                  {/* <i className="fab fa-twitter ms-icon"></i> */}
                  <TwitterIcon />
                </a>
                <a href="https://www.linkedin.com/company/rx-one">
                  {/* <i className="fab fa-linkedin-in ms-icon"></i> */}
                  <LinkedInIcon />
                </a>
                <a href="https://www.instagram.com/rxone_care?igsh=bzdsdTBzNjB3aTVr">
                  {/* <i className="fab fa-linkedin-in ms-icon"></i> */}
                  <InstagramIcon />
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h5 className="text-white mb-1">RxOneCare</h5>
              <div className="ms-seperator"></div>
              <div className="ms-footer-links">
                <Link to="/aboutus">About</Link>
                <br />
                <Link to="privacypolicy">Privacy Policy</Link>
                <br />
                <Link to="/terms">Terms of Usage</Link>
                <br />
                <Link to="/contactus">Contact Us</Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h5 className="text-white mt-4 mt-lg-0 mb-1">For Patients</h5>
              <div className="ms-seperator"></div>
              <div className="ms-footer-links">
                <a
                  href="https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN"
                  target="_blank"
                >
                  Android App
                </a>
                <br />
                <a
                  href="https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685"
                  target="_blank"
                >
                  IOS App
                </a>
                <br />
                <a href="https://rxone.app/treatment-advice">Care Path Consultation</a>
                <br />
                <a href="/blogs">Read Health Articles</a>
                <br />
                {/* <a href="https://rxone.app/specialists/MODE">
                  Allopathy Doctors
                </a>
                <br />
                <a href="https://rxone.app/specialists/HOME">
                  Homeopathy Doctors
                </a>
                <br />
                <a href="https://rxone.app/specialists/AYUR">
                  Ayurvedic Doctors{" "}
                </a> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h5 className="text-white mt-4 mt-lg-0 mb-1">For Providers</h5>
              <div className="ms-seperator"></div>
              <div className="ms-footer-links">
                <Link to="/">Provider Login</Link>
                <br />
                <Link to="/">Doctor Login</Link>
                <br />
                {/* <Link to="/">Patient Login</Link> */}
              </div>
            </div>
          </div>
          {/* <div className="row ms-bottom-footer mt-5">
                    <div className="col-md-12 text-center">
                        <p className="text-white pt-3">2022 © Rx Digital Labs Pvt. Ltd. All rights reserved.</p>
                    </div>
                </div> */}
        </div>
      </footer>
    </>
  );
}

export default Footer;
