import React, { useState, useEffect, useCallback } from "react";
import "../Css/treatment.css";
import ServiceCall from "../ServiceCall";
import { useNavigate, useLocation, NavLink, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import Calenderimg from "../Images/provider/treatment_img.png";
import { styled, useMediaQuery } from "@material-ui/core";
import whatsapp from "../Images/whatsapp.png";
import CenteredAlert from "./CenteredAlert";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(2, 0),
    backgroundColor: "#008b91",
    marginTop: "2rem",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "70px",
    left: theme.spacing(2),
    cursor: "pointer",
  },
  nextButton: {
    backgroundColor: "#008b91",
    marginTop: "2rem",
    color: "#fff",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      color: "#666",
    },
  },
  recaptcha: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontSize: "41px",
    marginTop: (props) =>
      props.isMobile ? "69px" : props.step === 1 ? "-293px" : "-362px",

    color: "#fff",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}));

function Treatment() {
  // States for tracking data and selected cost type (Min, Avg, Max)
  const [selectedCostType, setSelectedCostType] = useState('Min');
  const [treatdata, setTreatdata] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryname, setCountryname] = useState("India");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const[know, setKnow] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [preferredHospital, setPreferredHospital] = useState('');
const [preferredLocation, setPreferredLocation] = useState('');
const classes = useStyles({ step, isMobile });
const { route_key } = useParams();

const isValidPhoneNumber = (phoneNumber) => {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
};
const showAlerts = () => {
  setShowAlert(true);
  resetForm();
};

const resetForm = () => {
  setName("");
  setEmail("");
  setMessage("");
  setAge("");
  setGender("");
  setKnow("");
  setPhone("");
  setCountry("");
  setCity("");
};

const handlesubmit = (e) => {
  e.preventDefault();
  if (!recaptchaValue) {
    alert("Please complete the reCAPTCHA");
    return;
  }

  setLoading(true);
  const serviceid = "service_7yxtqsn";
  const templateid = "template_x3wusd7";
  const publickey = "4UpyJJj3P5JGeMZFz";
  const messageContent = `
  Patient Name: ${name}
  Age: ${age}
  Gender: ${gender}
  Phone Number: ${phoneCode}${phone}
  Email: ${email}
  Country: ${country}
  City: ${city}
  Chief Complaints: ${message}
`;

  const templateparams = {
    from_name: name,
    from_email: email,
    to_name: "web",
    message: messageContent,
  };

  emailjs
    .send(serviceid, templateid, templateparams, publickey)
    .then((response) => {
      setLoading(false);
      console.log("email set successfully", response);
      showAlerts();
    })
    .catch((error) => {
      setLoading(false);
      console.log("error sending email", error);
    });
};

const handlePhoneCodeChange = (event) => {
  setPhoneCode(event.target.value);
};
const handleRecaptchaChange = useCallback((value) => {
  setRecaptchaValue(value);
}, []);



useEffect(() => {
  const isValid =
    name.trim() !== "" &&
    age.trim() !== "" &&
    gender !== "" &&
    message.trim() !== "";
  setIsStep1Valid(isValid);
}, [name, age, gender, message]);

const titlle =
  "FREE Treatment Advice - Consult for Medical Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
const desc =
  "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";

const handleBack = () => {
  setStep(1);
};
const handleNext = () => {
  if (isStep1Valid) {
    setStep(2);
  }
};

const renderStep1 = () => (
  <>
    <TextField
      id="firstName"
      name="first_name"
      label="Patient Name"
      variant="filled"
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      required
      value={name}
      onChange={(e) => setName(e.target.value)}
      fullWidth
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <TextField
      id="age"
      name="age"
      label="Patient Age"
      variant="filled"
      value={age}
      onChange={(e) => setAge(e.target.value)}
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      fullWidth
      className={classes.nameField}
      onKeyPress={(e) => {
        // Allow only digits (0-9)
        const allowedChars = /^[0-9]*$/;
        if (!allowedChars.test(e.key)) {
          e.preventDefault();
        }
      }}
      inputProps={{
        maxLength: 3, // Optional: Limits the maximum length to 3 digits
      }}
      InputProps={{
        style: {
          backgroundColor: "#fff",
          marginTop: "7px",
          marginBottom: "-3px", // Set the background color here
        },
      }}
    />
    <FormControl
      variant="filled"
      style={{ marginBottom: "20px" }}
      fullWidth
      className={classes.nameField}
      classes={{ root: classes.root }} // Apply the custom styles
    >
      <InputLabel id="gender">Gender</InputLabel>
      <Select
        labelId="gender"
        id="gender"
        name="gender"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>
    </FormControl>

    <TextField
      multiline
      maxRows={4}
      id="chiefComplaints"
      name="message"
      label="Describe current medical problem"
      variant="filled"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />

    <TextField
      label="Preferred Hospital (Optional)"
      value={preferredHospital}
      onChange={(e) => setPreferredHospital(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />

    <TextField
      label="Preferred Treatment Location (Optional)"
      value={preferredLocation}
      onChange={(e) => setPreferredLocation(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px" }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />
    <Button
      className={classes.nextButton}
      variant="contained"
      onClick={handleNext}
      fullWidth
      disabled={!isStep1Valid}
    >
      Next
    </Button>
  </>
);

const renderStep2 = () => (
  <>
    <FormControl variant="filled" fullWidth className={classes.nameField}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="w-23"
          value={phoneCode} // Concatenate phoneCode and phone here
          onChange={handlePhoneCodeChange}
          classes={{ root: classes.root }}
          style={{ marginTop: "16px" }}
        >
          {countriesList &&
            countriesList.map((item, index) => (
              <MenuItem
                value={item.code}
                key={item.code + "-" + index}
                classes={{ root: classes.menuItem }} // Apply the custom styles to the MenuItem
              >
                {item.code}
              </MenuItem>
            ))}
        </Select>

        <InputLabel htmlFor="phoneNumber" style={{ marginLeft: "101px" }}>
          Phone Number*
        </InputLabel>
        <Input
          id="phoneNumber"
          name="phone"
          label="Phone Number"
          required
          value={phone}
          onChange={(e) => {
            const value = e.target.value;
            const allowedChars = /^[0-9]*$/; // Allow only digits

            if (allowedChars.test(value)) {
              setPhone(value.slice(0, 10)); // Limit the input to 10 digits
              setPhoneError(false); // Reset the error state
            } else {
              setPhoneError(true); // Set the error state if non-digit characters are entered
            }
          }}
          error={phoneError}
          style={{ flex: 1, marginLeft: 6, width: "100px" }}
          className={classes.nameField}
          InputProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
        />
      </div>
      {phoneError && (
        <FormHelperText error>
          Please enter a valid phone number.
        </FormHelperText>
      )}
    </FormControl>
    <TextField
      id="email"
      name="email"
      label="Email"
      variant="filled"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      fullWidth
      required
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
      inputProps={{
        pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
        title: "Please enter a valid email address",
      }}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <FormControl
      id="country"
      name="country"
      variant="filled"
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
    >
      <InputLabel id="gender">Select your country</InputLabel>
      <Select
        labelId="country"
        id="country"
        className="w-23"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        classes={{ root: classes.root }}
        InputProps={{
          style: { backgroundColor: "#fff" },
          inputProps: { style: { maxWidth: "100px" } },
        }}
        MenuProps={{
          components: {
            MenuItem: (props) => (
              <MenuItem
                {...props}
                classes={{ root: classes.menuItem }}
                style={{ maxWidth: "100px" }}
              >
                {props.children}
              </MenuItem>
            ),
          },
        }}
      >
        {countriesList &&
          countriesList.map((item, index) => (
            <MenuItem value={item.name} key={`${item.name}-${index}`}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    <FormControl
      variant="filled"
      style={{ marginBottom: "20px" }}
      fullWidth
      className={classes.nameField}
      classes={{ root: classes.root }} // Apply the custom styles
    >
      <InputLabel id="know">How Did you know about this</InputLabel>
      <Select
        labelId="know"
        id="know"
        name="know"
        value={know}
        onChange={(e) => setKnow(e.target.value)}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <MenuItem value="google search">Google Search</MenuItem>
        <MenuItem value="facebook">Facebook</MenuItem>
        <MenuItem value="friends/relatives">Friends/Relatives</MenuItem>
        <MenuItem value="past patients">Suggested by past patients</MenuItem>
        <MenuItem value="ambassador">Ambassador</MenuItem>
        <MenuItem value="other">Others</MenuItem>
      </Select>
    </FormControl>
    <TextField
      id="city"
      name="city"
      label="Enter your city"
      value={city}
      onChange={(e) => setCity(e.target.value)}
      variant="filled"
      required
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
      className={classes.nameField}
      InputProps={{
        style: {
          backgroundColor: "#fff", // Set the background color here
        },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
        },
      }}
    />
    <span
      style={{
        fontSize: "11px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      *We value your privacy, your details will be kept confidential. Refer
      our <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
    </span>
    <div className={classes.recaptcha}>
      <ReCAPTCHA
        sitekey="6LddplcqAAAAACztSGsmgN9xckbNg_5mn4p4t3A5"
        onChange={handleRecaptchaChange}
      />
    </div>
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      type="submit"
      fullWidth
      disabled={loading || !recaptchaValue}
    >
      {loading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        "Request Callback"
      )}
    </Button>
  </>
);

  // State for expanded FAQ section
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  // Navigation function for treatment advice page
  const navigate = useNavigate();
  const handleregis = () => {
    navigate("/treatment-advice");
  };

  // Function to toggle the expanded FAQ question
  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  // Function to handle cost type button click
  const handleCostTypeChange = (costType) => {
    setSelectedCostType(costType);
  };

  // Fetch treatment data
  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      try {
        const res = await ServiceCall.get(`rxone/fetch/procedure/${route_key}`);
        if (res) {
          setLoading(false);
          setTreatdata(res?.data);
          
        }
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };
    fetchdata();
  }, []);

  // Ensure treatdata fields have fallback values
  
  const MIN_PRICE_INR = treatdata?.cost_in_minimum_inr || 0;
  const MAX_PRICE_INR = treatdata?.cost_in_maximuim_inr || 0;
  const MIN_PRICE_USD = treatdata?.cost_in_minimum_usd || 0;
  const MAX_PRICE_USD = treatdata?.max_cost_in_usd || 0;

  // Calculate current price based on selected cost type
  
  let currentPriceINR = MIN_PRICE_INR;
  let currentPriceUSD = MIN_PRICE_USD;
  
  if (selectedCostType === 'Avg') {
   
    currentPriceINR = Math.round((MIN_PRICE_INR + MAX_PRICE_INR) / 2);
    currentPriceUSD = Math.round((MIN_PRICE_USD + MAX_PRICE_USD) / 2);
  } else if (selectedCostType === 'Max') {
    
    currentPriceINR = MAX_PRICE_INR;
    currentPriceUSD = MAX_PRICE_USD;
  }


  // const location = useLocation();
  // const routekey = location.state?.routekey;

  return (
    <>
    
      <section className="ms-book-banner d-flex align-items-center"style={{marginTop:"40px"}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6" style={{ height: "5px" }}>
              <h3 className="mb-3 ms-font-weight-600 text-center"></h3>
            </div>
          </div>
        </div>
      </section>

      <span
        style={{
          display: "block",
          width: "100vw", // Use 100vw for full viewport width
          backgroundColor: "rgb(0 92 116)",
          color: "#ffffff",
          padding: "6px",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "21px",
          position: "relative", // Add position relative
          left: "50%", // Move the element 50% to the left
          right: "50%", // Move the element 50% to the right
          marginLeft: "-50vw", // Compensate the left movement
          marginRight: "-50vw", // Compensate the right movement
        }}
      >
        Get <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span> Evaluation
        <button className="handlrs" onClick={handleregis}>
          <span style={{ fontSize: "17px" }}>Contact Us</span>
        </button>
      </span>
     
      <section className="outer-container">
        <div className="inner-container">
        {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Takes full height of viewport
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <span style={{ fontSize: "14px", fontWeight: "700", textTransform: "uppercase"  }}>
  <a
    href="https://rxone.app/"
    style={{
      color: "#018C92", // Inherit color from parent
      textDecoration: "none", // Remove underline
      
    }}
    onClick={(e) => {
      e.target.style.color = "#005C74"; // Change color when clicked
    }}
    onMouseEnter={(e) => {
      e.target.style.color = "#01A09E"; // Blue on hover
    }}
    onMouseLeave={(e) => {
      e.target.style.color = "#018C92"; // Reset color to inherited value on hover leave
    }}
  >
    Home
  </a>
  {" / "} {treatdata?.department} / {treatdata?.procedure}
</span>
)}
          <section>
            <div className="treatments-container">
              {/* Left Section */}
              <div className="left-section">
                <div className="rectangle">
                  <div className="cost-buttons">
                    <button 
                      className={`cost-btn ${selectedCostType === 'Min' ? 'active' : ''}`} 
                      onClick={() => handleCostTypeChange('Min')}
                    >
                      Min Cost
                    </button>
                    <button 
                      className={`cost-btn ${selectedCostType === 'Avg' ? 'active' : ''}`} 
                      onClick={() => handleCostTypeChange('Avg')}
                    >
                      Avg Cost
                    </button>
                    <button 
                      className={`cost-btn ${selectedCostType === 'Max' ? 'active' : ''}`} 
                      onClick={() => handleCostTypeChange('Max')}
                    >
                      Max Cost
                    </button>
                  </div>

                  {/* Price Information Below */}
                  <div className="price-info-box">
                    <div className="price-info">
                      <p>
                      INR {currentPriceINR}  / USD {currentPriceUSD} 
                      </p>
                    </div>
                  </div>

                  {/* Small rectangles below circles */}
                  <div className="rectangle-row">
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.procedure_type || ""} <br />
                      Procedure Type
                    </div>
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.procedure_duration_hr || ""} hrs <br />
                      Procedure Duration
                    </div>
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.hospital_stay_days || ""} days <br />
                      Hospital Stay
                    </div>
                  </div>
                  <div className="rectangle-row">
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.post_discahrge_stay_in_india_days || ""} <br />
                      Post discharge stay in India (days)
                    </div>
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.resume_work_weeks || ""} <br />
                      Resume Work
                    </div>
                    <div className="small-rectangle" style={{ fontWeight: "bold" }}>
                      {treatdata?.recurrence_percentage || ""} <br />
                      Recurrence
                    </div>
                  </div>

                  {/* Centered rectangle below the rows */}
                  <div className="centered-rectangle" style={{ fontWeight: "bold" }}>
                    {treatdata?.risk || ""} <br />
                    Risk
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="right-section">
                <h2 className="heading">{treatdata?.title || ""}</h2>
                <p className="description">{treatdata?.overview || ""}</p>
              </div>
            </div>
          </section>
          <section data-aos-duration="3000" className="ms-cureways-section">
        <span
          style={{
            padding: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "1rem" : "0",
            marginTop: "4rem",
            marginBottom: "-7.5rem",
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "-77px" : "0",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "100%" : "45%", // Set maxWidth to 100% for mobile
              // textAlign: "center", // Center align text on mobile
              marginTop: isMobile ? "0" : "355px", // Remove negative margin on mobile
            }}
          >
           <h1
      className={classes.header}
      style={{
        marginLeft: isMobile ? '-30px' : '-8.5rem', // Adjust margin depending on mobile or desktop
        fontSize: isMobile ? '2rem' : '2.5rem', // Decrease font size for mobile
      }}
    >
      Your Health, Our Priority
      <span style={{ color: '#fff' }}></span>
    </h1>
            <ul className="mb-2">
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Save upto 40% treatment costs in India
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Get treatment in the top hospitals in India 
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Connect with top doctors and specialists in India
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Discover the best treatment options available for you
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Receive a personalized care plan tailored to your needs
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Feel confident with top-level support throughout care journey
              </li>
            </ul>

            <p
      style={{
        fontWeight: 'bold',
        color: '#fff',
        fontSize: isMobile ? '20px' : '21px', // Adjust font size based on screen size
        lineHeight: isMobile ? '1.3' : '1.5', // Optional: Adjust line height for readability
        marginLeft: isMobile ? '30px' : '34px',
      }}
    >
      ....And get assistance for everything in between
    </p>

            {/* <img src={Calenderimg} style={{textAlign: "center", marginTop: "2rem",  height: "26rem", width: "27rem", borderRadius: "31px", marginLeft: isMobile ? "-1.3rem" : ""}} /> */}

          </div>
         
          <div style={{ width: isMobile ? "100%" : "auto" }}>
        <Container component="main" maxWidth="xs">
          <div
            className="provider_revolutionize_item"
            style={{
              backgroundColor: "#fff",
              width: "100%",
              borderRadius: "55px",
              boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
              position: "relative",
              padding: "2rem",
            }}
          >
            {step === 2 && (
              <ArrowBackIosNewIcon
                className={classes.arrowIcon}
                onClick={handleBack}
              />
            )}
            <h4
              className="ms-font-weight-600 mb-4 ms-primary-color"
              style={{ textAlign: "center" }}
            >
              Contact Us
            </h4>
            <form
              style={{ textAlign: "center", width: "100%" }}
              onSubmit={handlesubmit}
            >
              {step === 1 ? renderStep1() : renderStep2()}
            </form>
          </div>
        </Container>
      </div>
        </div>
      </section>
      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}
          {/* FAQ Section */}
          <h5 className="mb-4 ms-font-weight-600 ms-primary-color" style={{ marginLeft: "1rem", fontSize: "1.4rem", marginTop:"20px"}}>
            Frequently Asked Questions
          </h5>

          <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            {treatdata?.FAQ?.map((question) => (
              <li className="languages card" key={question.id}>
                <div>
                  <strong>{question.question}</strong>
                  <button
                    onClick={() => toggleQuestion(question.id)}
                    style={{
                      border: "none",
                      background: "none",
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                      marginTop: "-22px",
                    }}
                  >
                    <span>{expandedQuestions.includes(question.id) ? "▲" : "▼"}</span>
                  </button>
                </div>
                {expandedQuestions.includes(question.id) && <p>{question.answer}</p>}
              </li>
            ))}
          </ul>
        </div>
      </section>
       
    </>
  
  );
}

export default Treatment;