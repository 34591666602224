import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import '../Css/joinus.css';
import { countriesList } from "../utility/constants";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { medicals } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import { hip } from "./config";
import { faqsss } from "./config";
import docgirl from "../Images/home/handshake.png";
import hipani from "../Images/home/hip-animation.mp4";
import belt1 from "../Images/home/belt1.png";
import belt2 from "../Images/home/belt2.png";
import belt3 from "../Images/home/belt3.png";
import fortis from "../Images/home/Fortis_Healthcare_Logo.png";
import amrita from "../Images/home/amrita-hos.png";
import birla from "../Images/home/bif2.png";
import asian from "../Images/home/asian.png";
import shahdry from "../Images/home/sahyadri-logo.png";
import Marengo from "../Images/home/mar2.png";
import glenagles from "../Images/home/glenagles.png";
import yatharth from "../Images/home/Yatharth.png";
import motherland from "../Images/home/motherland.png";
import bima from "../Images/home/bimacare.png"
import OwlCarousel from "react-owl-carousel";
import doctor1 from "../Images/home/dr-ashok2.jpg";
import doctor2 from "../Images/home/dr.lalit3.jpg";
import doctor3 from "../Images/home/dr-subrat_.jpg";
import doctor4 from "../Images/home/dr.sachin.jpeg";
import doctor5 from "../Images/home/dr-vivek-jawali.jpg";
import doctor6 from "../Images/home/dr-vijaay.jpg";
import doctor7 from "../Images/home/aditya.jpg";
import doctor8 from "../Images/home/Dr.-Parimala-Devi.png";
import doctor9 from "../Images/home/Manjinder_Sandhu.jpg";
import doctor10 from "../Images/home/Dr-Mohan-Keshavamurthy.jpg";
import bg1 from "../Images/home/ripple.jpg";
import bg2 from "../Images/home/c11.jpg";
import bg3 from "../Images/home/c7.jpg";
import bg4 from "../Images/home/c8.jpg";
import bg7 from "../Images/home/c15.jpg";
import bg8 from "../Images/home/c16.jpg";
import bg9 from "../Images/home/c12.jpg";
import ripple from "../Images/home/ripple.jpg";
import belt from "../Images/home/hip.png";
import agent from "../Images/home/people.png";
import b1 from "../Images/SVG/doc.svg";
import b2 from "../Images/SVG/med.svg";
import b3 from "../Images/SVG/part.svg";
import b4 from "../Images/SVG/hos.svg";
import b5 from "../Images/SVG/hand.svg";

import {
    TextField,
    Button,
    Container,
    makeStyles,
    FormControl,
    InputLabel,
    InputAdornment,
    Input,
    MenuItem,
    FormHelperText,
    Select,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import Honor from "./Honor";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(4),
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
        },
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
        },
    },
    button: {
        margin: theme.spacing(2, 0),
        backgroundColor: "#199a8e",
        "&:hover": {
            // Remove hover effect
            backgroundColor: "#199a8e",
        },
    },
    salutationFormControl: {
        width: "27%", // Adjust the width of the salutation select
    },
    gender: {
        width: "30%",
    },
    nameContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    nameField: {
        background: "white",
    },
    select: {
        border: "none", // Remove border
        width: "50px", // Adjust the width as needed
    },
}));

const Joinus = () => {
    const classes = useStyles();
    const [loader, setLoader] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [phoneCode, setPhoneCode] = useState("+91"); 
    const [message, setMessage] = useState("");
    const [location, setLocation] = useState("");

    const [name, setName] = useState("");
    const [pin, setPin] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
    const isTablet = window.innerWidth > 768 && window.innerWidth <= 1024;
    const videoRef = useRef(null);
    const sectionRef = useRef(null);
    const [userType, setUserType] = useState("");
    const images2 = [bg1, bg2, bg3 , bg4 , bg7 , bg8 ,bg9 ];
     const bannerImages = [
        fortis,
        amrita,
        birla,
        asian,
        shahdry,
        Marengo,
        yatharth,
        bima,
        glenagles,
        motherland
      ];
      const headings = [
        <>
          Elevate your <span style={{ color: "#fff" }}>Medical Practice</span> with SMART EMR
        </>,
        <>
          Promote your Care Services <span style={{ color: "#fff" }}>(including Home Care)</span> at RxOne Marketplace
        </>,
        <>
         Unleash potential of your <span style={{ color: "#fff" }}>Medical Devices</span> through RxOne 
        </>,
        <>
          <span style={{ color: "#fff" }}>Refer Patients</span> across the Globe to India
        </>,
        <>
          Seamless <span style={{ color: "#fff" }}>Data Integration</span> with RxOne Platform
        </>,
      ];
      
      const image = [b1, b3, b2, b5, b4];
      const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % headings.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, []);
      
      useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              // Play the video when the section enters the viewport
              if (videoRef.current) {
                const iframe = videoRef.current.contentWindow;
                iframe.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
              }
            } else {
              // Pause the video when the section leaves the viewport
              if (videoRef.current) {
                const iframe = videoRef.current.contentWindow;
                iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
              }
            }
          },
          { threshold: .1 } // Adjust threshold for triggering
        );
    
        if (sectionRef.current) {
          observer.observe(sectionRef.current);
        }
    
        return () => {
          if (sectionRef.current) {
            observer.unobserve(sectionRef.current);
          }
        };
      }, []);
  
      const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
      useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
          };
      
          window.addEventListener("resize", handleResize);
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, []);
   
    const images = [belt1, belt2, belt3];
    const [scrollY, setScrollY] = useState(0);

   
    const isValidPhoneNumber = (phoneNumber) => {
      const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
      return numericPhoneNumber.length >= 7 && numericPhoneNumber.length <= 15;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Validate phone number
      if (!isValidPhoneNumber(phone)) {
        setPhoneError(true);
        return;
      }
      setPhoneError(false);
  
      setLoading(true); // Show loader
  
      const serviceid = "service_7yxtqsn";
      const templateid = "template_x3wusd7";
      const publickey = "4UpyJJj3P5JGeMZFz";
  
      const messageContent = `
        Type: 'partner'
        Name: ${name}
        Number: ${phoneCode}${phone}
        Partner As: ${userType} 
        Email: ${email}
        Address: ${location}
        Reason or query: ${message}
      `;
  
      const templateparams = {
        from_name: name,
        from_email: email,
        to_name: "web",
        message: messageContent,
      };
  
      emailjs
        .send(serviceid, templateid, templateparams, publickey)
        .then((response) => {
          console.log("Email sent successfully", response);
          setSuccessMessage("Your request has been sent successfully!");
  
          // Reset form fields
          setName("");
          setEmail("");
          setPhone("");
          setPhoneCode("");
          setMessage("");
          setLocation("");
          setUserType("");
  
          setTimeout(() => setSuccessMessage(""), 5000); // Hide message after 5 sec
        })
        .catch((error) => {
          console.log("Error sending email", error);
        })
        .finally(() => setLoading(false)); // Hide loader
    };
  
    const [expandedQuestions, setExpandedQuestions] = useState([]);

    const handlesubmit = (e) => {
      e.preventDefault();
      const serviceid = "service_7yxtqsn";
      const templateid = "template_x3wusd7";
      const publickey = "4UpyJJj3P5JGeMZFz";
    
      const messageContent = `
      Type: 'partner'
      Name: ${name}
      Number: ${phoneCode}${phone}
      Partner As: ${userType} 
        Email: ${email}
      Address: ${location}
      reason or query :${message}
  `;

    
      const templateparams = {
        from_name: name,
        from_email: email,
        to_name: "web",
        message: messageContent,
      };
    
      emailjs
        .send(serviceid, templateid, templateparams, publickey)
        .then((response) => {
          console.log("Email sent successfully", response);
    
          // Reset form fields
          setName("");
          setEmail("");
          setPhone("");
          setPhoneCode("");
          setCity("");
          setPin("");
          setMessage("");
          setLocation("");
          setUserType("");
        })
        .catch((error) => {
          console.log("Error sending email", error);
        });
    };
    
    const toggleQuestion = (id) => {
        if (expandedQuestions.includes(id)) {
            setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
        } else {
            setExpandedQuestions([...expandedQuestions, id]);
        }
    };
  const doctors = [
    {
      name: "Dr. Ashok Seth",
      image: doctor1,
      specialization: "Interventional Cardiology",
      hospital: "Fortis Escorts Heart Institute, New Delhi",
      achievements: [
        "Dr. Seth has been awarded by the National Honour of Padma Shri in 2003",
        "Padma Bhushan in 2015 by the President of India",
        "B.C. Roy National Award for the most Eminent Medical Person by the President of India in January 2017.",
      ],
      profileLink:
        "https://rxone.app/profile/dr-ashok-seth-cardiac-ablation-cardiac-catheterisation-cardioversion-carotid-angioplasty-and-stenting-coronary-angioplasty-bypass-surgery-mitral-heart-valve-replacement-ct-angiogram-implantable-cardioverter-defibrillators-icds-pacemaker-implantation-acute-aortic-dissection-cardiac-rehabilitation-heart-conditions-asd-vsd-device-closure-hypertension-treatment-patent-foramen-ovale-new-delhi",
    },
    {
      name: "Dr. Lalit Kumar",
      image: doctor2,
      specialization: "Medical Oncology",
      hospital: "Artemis Hospital, Gurgaon",
      achievements: [
        "Dr. Lalit Kumar has been awarded by the National Honour of Padma Shri Award (2014)",
        "Dr. B.C. Roy Award (2008) - the highest award by the Indian government in the medical category",
        "Indian Council of Medical Research (ICMR) Award",
      ], profileLink:
      "https://rxone.app/profile/dr-lalit-kumar-bmt-oncology-gurgaon",
 
    },
    {
      name: "Dr. Subrat Kumar Acharya",
      image: doctor3,
      specialization: "Hepatology",
      hospital: "Fortis Escorts, Delhi",
      achievements: [
        "Dr. Subrat Kumar has been honoured with several awards and accolades. Prominent among them is the Padma Shree which he received in 2014.",
        "Dr. Subrat Kumar Acharya has also received the prestigious Samanta Chandrasekhar & JC Bose awards",
      ],
      profileLink:
      "https://rxone.app/profile/dr-subrat-kumar-acharya-gastroenterology-and-hepatobiliary-sciences-|-gastroenterology-and-hepatobiliary-sciences-new-delhi",
 
    },
    {
      name: "Dr. Sachin Karkamkar",
      image: doctor4,
      specialization: "Orthopedics",
      hospital: "Sahyadri Hospital, Pune",
      achievements: [
        "Padmashree Dr. KH Sancheti Gold Medal (MS Ortho)",
        "Col KD Khare Award for Orthopaedic Excellence",
      ],
      profileLink:
      "https://rxone.app/profile/dr-sachin-karkamkar-sports-medicine-knee-hip-and-shoulder-replacement-surgery-knee-and-shoulder-arthroscopy-complex-trauma-and-adult-reconstruction-surgery-arthritis-management-awar-acl-pcl-reconstruction-physiotherapy-for-sports-injury-rehabilitation-pune",
 
    },
    {
      name: "Dr. Vivek Jawali",
      image: doctor5,
      specialization: "Cardiovascular Surgery",
      hospital: "Fortis Hospitals, Bangalore",
      achievements: [
        "B C Roy Award for Medical Excellence",
        "Lifetime Achievement Award from Harvard University for Medical Excellence in India",
        "Global Recognition for ‘Awake’ Open Heart Surgery",
      ],
      profileLink:
      "https://rxone.app/profile/dr-vivek-jawali-cardiothoracic-surgeon-bangalore",
 
    },
    {
      name: "Dr. G.R Vijay Kumar",
      image: doctor6,
      specialization: "General Surgery",
      hospital: "Fortis Hospital, Kolkata",
      achievements: [
        "B.C. Roy Memorial Award for Best Student, Medical College, Calcutta, 1989",
        "University Gold Medal in Surgery, Calcutta University, 1988.",
        "University Gold Medal in Obstetrics and Gynecology, Calcutta University, 1988",
      ],
      profileLink:
      "https://rxone.app/profile/dr-gr-vijay-kumar-neurosurgery-kolkata",
 
    },
    {
      name: "Dr Aditya Gupta",
      image: doctor7,
      specialization: "Neurosurgery",
      hospital: "Artemis Hospital, Gurgaon",
      achievements: [
        "Chief of Army Staff Award, 2012.",
        "BOYSCAST Fellowship Award, President of India, 2006.",
        "Best Research Paper Award, IES, 1999.",
      ],
      profileLink:
      "https://rxone.app/profile/dr-aditya-gupta-neurosurgeon-gurgaon",
 
    },
    {
      name: "Dr. Parimala Devi",
      image: doctor8,
      specialization: "Obstetrics and Gynecology",
      hospital: "Fortis Hospital, Bangalore",
      achievements: [
        "Mother Teresa Award.",
        "Aryabhata National Award – Human Service",
        "Gold Medal for Active Management of Septic Abortion – Rotary Club, Bangalore – 1998.",
      ],
      profileLink:
      "https://rxone.app/profile/dr-parimala-devi-gynecologist-obstetrician-infertility-specialist-laparoscopic-surgeon-obs-_-gyn-bangalore",
 
    },
    {
      name: "Dr. (Col) Manjinder Sandhu",
      image: doctor9,
      specialization: "Cardiology",
      hospital: "Fortis Memorial Research Institute",
      achievements: [
        "Times Health Care Achievers Award 2018.",
        "Commendation, Ministry of Social Welfare, Sri Lanka - 2003",
        "Lt Gen Inder Singh Gold Medal - 1994",
      ],
      profileLink:
      "https://rxone.app/profile/dr-col-manjinder-sandhu-complex-coronary-interventions-including-rotablator-ivl-and-laser-endovascular-procedures-such-as-evar-tevar-and-coarctation-of-aorta-peripheral-interventions-including-ilio-femoral-and-renal-denervation-intracoronary-imaging-techniques-like-ivus-and-oct-devices-including-pacemaker-icd-crt-d-and-ccm-cardiac-contractility-modulation-valvular-interventions-such-as-bmv-tavr-mitraclip-and-tricuspid-valve-procedures-gurgaon",
 
    },
    {
      name: "Dr. Mohan Keshavamurthy",
      image: doctor10,
      specialization: "Urology",
      hospital: "Fortis Hospital, Bangalore",
      achievements: [
        "3000 laser-enabled Transurethral prostate procedures (LASER TURP)",
        "2500 laser fragmentation of kidney (RIRS) and ureteric stones(URS)",
        "2500 kidney transplants and 75 pancreas transplants",
      ],
      profileLink:
      "https://rxone.app/profile/dr-mohan-keshavamurthy-implantation-of-flexible-and-inflatable-penile-prosthesis-and-penile-lengthening-procedures-artificial-sphincter-insertion-and-redo-hypospadias-surgery-pelvic--urologic-surgeries-transurethral-prostate-procedures-laser-turp-kidney--pancreas-transplant-uro-oncological-procedures-bangalore",
 
    },
  ];

  const extendedDoctors = [...doctors, ...doctors];
    const titlle = 
    "Become a Partner | RxOne";
const desc = 
    "Join RxOne and be part of a growing healthcare community dedicated to innovation, support, and excellence. Connect with professionals, explore opportunities, and make a difference today.";
    const scrollToForm = () => {
      const formSection = document.getElementById("formSection");
      if (formSection) {
        formSection.scrollIntoView({ behavior: "smooth" });
      }
    };
    return (
      <>
        <Helmet>{<title>{titlle}</title>}</Helmet>
        <Helmet>{<meta name="description" content={desc} />}</Helmet>

        <section
    id="formSection"
    data-aos-duration="3000"
    className="ms-cureways-section sliding-bg-container"
    style={{ marginTop: "30px", backgroundColor: "rgb(0, 145, 133)" }}
  >
    {/* Main Form Content */}
    <div
    style={{
      padding: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: isMobile ? "column" : "row",
      marginTop: isMobile ? "6rem" : "5rem",
      marginBottom: "-6rem",
      position: "relative",
      zIndex: 2,
      width: "100%",
    }}
    >
    {/* Left Side (Image + Heading) */}
    <div
    style={{
    width: isMobile ? "100%" : "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: isMobile ? "20px" : "40px",
    transition: "all 0.5s ease-in-out",
    }}
  >
    {/* Image */}
    <div
    className="image-container"
    style={{
      width: "100%",
      maxWidth: isMobile ? "180px" : "240px",
      animation: "fadeIn 0.8s ease-in-out",
    }}
    >
    <img
      src={image[currentIndex]}
      alt="RxOne"
      className="heading-image"
      style={{
      width: "100%",
      height: "auto",
      
      
      }}
    />
    </div>

    {/* Heading */}
    <div
    className="hip-pro-plus-container2"
    style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    marginTop: isMobile ? "40px" : "30px",
    animation: "slideUp 0.8s ease-in-out",
    }}
  >
    <h1
    key={currentIndex}
    className="hip-pro-plus-heading3"
    style={{
      fontSize: isMobile ? "1.1rem" : "3rem",
      fontWeight: "700",
      color: "#003366",
      maxWidth: "85%",
      lineHeight: "1.4",
      textAlign: "center",
      padding: isMobile ? " " : "15px 30px",
    }}
    >
    {headings[currentIndex]}
    </h1>
  </div>

  </div>


    {/* Right Side (Form - Fixed Position) */}
    <div
      style={{
      width: isMobile ? "90%" : "40%",
      minWidth: isMobile ? "90%" : "400px",
      maxWidth: isMobile ? "90%" : "400px",
      backgroundColor: "#fff",
      padding: isMobile ? "15px" : "25px",
      borderRadius: "12px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      marginBottom: isMobile ? "100px" : "",
      position: "sticky",
      top: "60px",
      }}
    >
      <form onSubmit={handleSubmit}>
      {/* Full Name */}
      <TextField
        label="Full Name"
        variant="filled"
        style={{ marginBottom: "15px", backgroundColor: "white" }}
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />

      {/* Phone Number Input */}
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <FormControl style={{ minWidth: "100px" }}>
        <InputLabel>Code</InputLabel>
        <Select value={phoneCode} onChange={(e) => setPhoneCode(e.target.value)}>
          <MenuItem value="+1">+1</MenuItem>
          <MenuItem value="+91">+91</MenuItem>
        </Select>
        </FormControl>

        <TextField
        label="Phone Number"
        variant="outlined"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
        fullWidth
        />
      </div>

      {/* Partner Type */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Partner As</InputLabel>
        <Select value={userType} onChange={(e) => setUserType(e.target.value)}>
        <MenuItem value="doctor">Doctor</MenuItem>
        <MenuItem value="hospital">Hospital</MenuItem>
        <MenuItem value="clinic">Clinic</MenuItem>
        <MenuItem value="individual">Individual</MenuItem>
        </Select>
      </FormControl>

      {/* Location */}
      <TextField label="Location" variant="outlined" value={location} onChange={(e) => setLocation(e.target.value)} fullWidth required margin="normal" />

      {/* Email */}
      <TextField label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth required margin="normal" />

      {/* Message */}
      <TextField label="Message" variant="outlined" value={message} onChange={(e) => setMessage(e.target.value)} multiline rows={2} fullWidth required margin="normal" />

      {/* Submit Button with Loader */}
      <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: "10px", backgroundColor: "#009185" }} disabled={loading}>
        {loading ? <CircularProgress size={24} style={{ color: "white" }} /> : "Submit"}
      </Button>

      {/* Success Message */}
      {successMessage && <p style={{ color: "green", textAlign: "center", marginTop: "10px" }}>{successMessage}</p>}
      </form>
    </div>
    </div>
  </section>


        <br></br>
        <section className="partnership-section">
      <div className="partnership-container">
      <div className="responsive-heading">
        <h1>Join Our Journey</h1>
        <p className="responsive-description">
        Discover how you can collaborate with us and learn what sets us apart from others in the  worldwide industry.
        </p>
      </div>
      <div className="partnership-content">
        {/* Left Section */}
        <div className="partner-eligibility">
      
    <h2 style={{ 
    fontSize: '1.8rem', 
    color: '#2c3e50', 
    marginBottom: '15px', 
    fontWeight: '600', 
    textAlign: 'center',
    color:"#fff", 
    }}>
    Who Can Be Our Partner?
    </h2>


        <ul className="eligibility-points">
          <li>Hospitals looking for advanced collaborations.</li>
          <li>Clinics aiming to expand their patient outreach.</li>
          <li>Health tech startups with innovative solutions.</li>
          <li>Insurance providers focused on customer satisfaction.</li>
          <li>NGOs and health organizations aiming to create impact.</li>
        </ul>
        </div>

        {/* Right Section */}
        <div className="differentiators">
        <h2 style={{ 
    fontSize: '1.8rem', 
    color: '#2c3e50', 
    marginBottom: '15px', 
    fontWeight: '600', 
    textAlign: 'center',
    color:"#fff", 
    }}>How We Are Different?</h2>
        <ul className="differentiators-points">
          <li>Personalized healthcare solutions for every partner.</li>
          <li>Cutting-edge technology to enhance patient outcomes.</li>
          <li>Global network and local expertise.</li>
          <li>24/7 dedicated partner support and consultation.</li>
          <li>Proven track record of successful partnerships.</li>
        </ul>
        </div>
      </div>
      {/* Next Button */}
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
      >
      <div
        onClick={scrollToForm}
        className="ms-secondary-btn ms-header-btn"
        style={{
        marginBottom: "20px",
        padding: "6px 15px",
        textAlign: "center",
        cursor: "pointer",
        }}
      >
        Next You?
      </div>
      </div>
      </div>
    </section>
     
        <section
              id="care-paths-section"
              data-aos-duration="3000"
              className="ms-cured-section"
              style={{marginTop:"-74px"}}
              >
              
                <h2
                className="honor-heading"
                style={{ textAlign: "center",  }}
                >
                Our Partners
                </h2>
                <p className="responsive-description"style={{ textAlign: "center", marginBottom: "5rem", marginTop:"0px" }}
                >
        Discover how you can collaborate with us and learn what sets us apart from others in the industry.
        </p>
                {bannerImages && Array.isArray(bannerImages) && (
                <OwlCarousel
                  className="owl-theme"
                  autoplay={true}
                  autoplayTimeout={1200}
                  smartSpeed={800}
                  loop={true}
                  margin={20}
                  dots={false}
                  responsive={{
                  0: {
                    items: 2, // Show 1 item on very small screens
                  },
                  480: {
                    items: 2, // Show 2 items on slightly larger screens
                  },
                  768: {
                    items: 3, // Show 3 items on tablet and larger screens
                  },
                  }}
                >
                  {bannerImages.map((bannerImg, index) => (
                  <div key={index} className="itemss">
                    <div className="customImg">
                    <img
                      src={bannerImg}
                      alt={`Slide ${index}`}

                      //   style={{ height: "200px", width: "200px", objectFit: "contain" }}
                    />
                    </div>
                  </div>
                  
                  ))}
                 
                </OwlCarousel>
                
                )}
           <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "60px",
      }}
      >
      <div
        onClick={scrollToForm}
        className="ms-secondary-btn ms-header-btn"
        style={{
        marginBottom: "20px",
        padding: "6px 15px",
        textAlign: "center",
        cursor: "pointer",
        }}
      >
        Next You?
      </div>
      </div>
              </section>
              
        <section className="honor-section"style={{marginBottom:"1.5rem"}}>
        <div className="honor-section-container">
      <h2 className="honor-heading">Star Doctors</h2>
      <p className="honor-description">
      We are proud to be associated with top doctors and specialists across
      India. Below is the list of decorated doctors, who have been awarded
      and recognized at the national level.
      </p>
      <div className="marquee-container">
      <div className="marquee">
        {extendedDoctors.map((doctor, index) => (
        <a
          href={doctor.profileLink}
          target="_blank"
          rel="noopener noreferrer"
          className="honor-card-link"
          key={index}
        >
          <div className="honor-card-custom">
          <div className="honor-image-container">
            <img
            src={doctor.image}
            alt={doctor.name}
            className="honor-doctor-image"
            />
            <div className="honor-achievement-overlay">
            <ul className="honor-achievement-list">
              {doctor.achievements.map((achievement, idx) => (
              <li key={idx} className="honor-achievement-item">
                {achievement}
              </li>
              ))}
            </ul>
            </div>
          </div>
          <div className="honor-doctor-details">
            <div className="honor-doctor-name">{doctor.name}</div>
            <div className="honor-doctor-specialization">
            {doctor.specialization}
            </div>
            <div className="honor-doctor-hospital">{doctor.hospital}</div>
          </div>
          </div>
        </a>
        ))}
      </div>
      </div>
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
      >
      <div
        onClick={scrollToForm}
        className="ms-secondary-btn ms-header-btn"
        style={{
        marginBottom: "20px",
        padding: "6px 15px",
        textAlign: "center",
        cursor: "pointer",
        }}
      >
        Next You?
      </div>
      </div>
    </div>
      
      </section>

     <section className="responsive-rectangle">
    {/* Heading */}
    <h2 className="responsive-heading">Device We Have</h2>

    {/* Description */}
    <p className="responsive-description">
    Explore the advanced devices we use to deliver exceptional care. Our commitment to innovation and excellence is showcased in these cutting-edge devices.
    </p>

    {/* Device Boxes Container */}
    <div className="responsive-box-container">
    {/* First Box */}
    <div className="responsive-box">
      <img src={ripple} alt="Ripple Device" className="responsive-box-image" />
      <ul className="responsive-box-points">
      <li>High Precision</li>
      <li>Reliable Performance</li>
      <li>Advanced Technology</li>
      </ul>
    </div>

    {/* Second Box */}
     
    <div className="responsive-box">
      <NavLink to="/devices/elderly-care/ripple-hippro">
    <img src={belt} alt="Doc Girl" className="responsive-box-image" />
    </NavLink>
      <ul className="responsive-box-points">
      <li>User-Friendly Design</li>
      <li>Enhanced Efficiency</li>
      <li>Compact and Portable</li>
      </ul>
    </div>
    
    </div>
    
    {/* Button Section */}
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
      >
      <div
        onClick={scrollToForm}
        className="ms-secondary-btn ms-header-btn"
        style={{
        marginBottom: "20px",
        padding: "6px 15px",
        textAlign: "center",
        cursor: "pointer",
        }}
      >
        Next You?
      </div>
      </div>
  </section>

    
     

  <section className="custom-section">
    <div className="custom-container">
    <div className="responsive-heading">
      <h1>Refer to Us</h1>
      <p className="responsive-description">
      Anyone who has the potential to refer a patient can work as our partner. Some of our existing partner list includes:
      </p>
    </div>
    <div className="custom-content">
      <div className="custom-image">
      <img src={agent} alt="Refer to Us" />
      </div>
      <div className="custom-points">
      <div className="points-row">
    <span> General Physicians, Doctors, and Surgeons.</span>
    <span> Individual partners from India and beyond.</span>
  </div>
  <div className="points-row">
    <span> Healthcare technology companies and medical device providers.</span>
    <span> Insurance companies offering health coverage solutions.</span>
  </div>
  <div className="points-row">
    <span> Travel agencies facilitating medical tourism.</span>
    <span> Government health organizations, NGOs, and patients seeking the best treatment.</span>
  </div>

      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
      >
      <div
        onClick={scrollToForm}
        className="ms-secondary-btn ms-header-btn"
        style={{
        marginBottom: "20px",
        padding: "6px 15px",
        textAlign: "center",
        cursor: "pointer",
        }}
      >
        Next You?
      </div>
      </div>
    </div>
  </section>





     <br></br>
    
    
  {/*       
      <div className="responsive-heading" >
     <h1> Frequently Asked Questions</h1>
    </div>

    <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
      {hip.map((faq) => (
      <li className="languages card" key={faq.id}>
        <div>
        <strong>{faq.question}</strong>
        <button
          onClick={() => toggleQuestion(faq.id)}
          style={{
          border: "none",
          background: "none",
          display: "flex",
          justifyContent: "end",
          width: "100%",
          marginTop: "-22px",
          }}
        >
          <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
        </button>
        </div>
        {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
      </li>
      ))}
    </ul> */}
    </>
    );
};
export default Joinus;