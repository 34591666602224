import React, { useState, useEffect , useRef } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import "../Css/header2.css";
import Login from "./user/Login";
import header__logo from "../Images/home/RxOne_Color.png";
import header__logo1 from "../Images/home/RxOne_White.png";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import ServiceCall from "../ServiceCall";
import SearchIcon from "@material-ui/icons/Search";

const Header2 = () => {
  const style = {
    // Adding media querry..
    "@media (max-width: 700px)": {
      display: "none",
    },
  };
  const navigate = useNavigate();
  const mode = process.env.REACT_APP_MODE; // Replace with actual value
  const [islogin, setlogin] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const { som } = useParams();
  const [showHipProDropdown, setShowHipProDropdown] = useState(false);
  const [nav, isNav] = useState(false);
  const [swap, setSwap] = useState("Download App");
  const media = window.matchMedia("(max-width: 768px)").matches;
  const [specialties, setSpecialties] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [doctordata, setDoctordata] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  // const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [treatdata, setTreatdata] = useState([]);
  const [departdata, setDepartdata] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showProceduresDropdown, setShowProceduresDropdown] = useState(false);
  const [procedures, setProcedures] = useState([]);
  const [showProcedures, setShowProcedures] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [lookup, setlookup] = useState("");
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showMobileAboutDropdown, setShowMobileAboutDropdown] = useState(false);
  const toggleMobileAboutDropdown = (state) => {
    setShowMobileAboutDropdown(state);

  };
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  console.log(34422222, searchResults)
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);
  const [showDevicesDropdown, setShowDevicesDropdown] = useState(false);
  const [showMobileDevicesDropdown,setShowMobileDevicesDropdown] = useState(false);
  const toggleHipProDropdown = () => {
    setShowHipProDropdown(prevState => !prevState);
  };
  const [showOldCareDropdown, setShowOldCareDropdown] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu__toggle__content")) {
        setMenuToggle(false);
      }
    };
  
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);
  
  const transitionNav = () => {
    if (window.scrollY > 100) {
      isNav(true);
    } else {
      isNav(false);
    }
  };

  useEffect(() => {
    if (!islogin) {
      document.body.style.overflowY = "scroll";
    }
    window.addEventListener("scroll", transitionNav);
    return () => window.addEventListener("scroll", transitionNav);
  });

  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setlookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);

  useEffect(() => {
    const treatmentdata = async () => {
      try {
        const res = await ServiceCall.gett(
          "rxone/fetch/distinct/treatment/departments"
        );
        if (res) {
          setTreatdata(res?.data?.departments);
        }
      } catch (err) {}
    };
    treatmentdata();
  }, []);
  const filterDoctorsBySpecialty = (specialty) => {
    navigate(`/specialists/${specialty.code}`, {});
    // setShowDropdown(false);
  };
  const handleSearch = async () => {
    const trimmedSearch = search.trim();
    
    if (!trimmedSearch) {
      setSearchResults([]);
      setShowSearchDropdown(false);
      return;
    }
  
    setLoading(true);
    setShowSearchDropdown(true); // Show dropdown while loading
  
    try {
      const body = { search_text: trimmedSearch };
      const response = await ServiceCall.post(`/rxopd/rxone/globle/search/${mode}`, body);
  
      const { hospitals = [], treatments = [], doctors = [] } = response.data;
  
      // Format search results with image_url
      const formattedResults = [
        ...hospitals.map(hospital => ({
          name: hospital.name,
          route_key: hospital.route_key,
          type: "hospital",
          image_url: hospital.image_url || "default-hospital.png" // Use a default image if missing
        })),
        ...treatments.map(treatment => ({
          name: treatment.procedure,
          route_key: treatment.route_key,
          type: "treatment",
          image_url: treatment.image_url || "default-treatment.png"
        })),
        ...doctors.map(doctor => ({
          name: doctor.name,
          route_key: doctor.route_key,
          type: "doctor",
          image_url: doctor.image_url || "default-doctor.png"
        }))
      ];
  
      // Handle empty results
      setSearchResults(
        formattedResults.length > 0
          ? formattedResults
          : [{ name: "No results found", type: "none" }]
      );
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults([{ name: "Error fetching results", type: "none" }]);
    }
  
    setLoading(false);
  };
  
  

  // Close search dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Function to handle selection and navigate to the correct page
  const handleSelect = (result, event) => {
    event.stopPropagation(); // Prevent parent handlers from interfering
  
    const ROUTE_BASE = {
      doctor: "/profile",
      treatment: "/treatments",
      hospital: "/hospitalpage",
    };
  
    const url = ROUTE_BASE[result.type];
    if (!url) return;
  
    console.log("Navigating to:", url + `/${result.route_key}`);
  
    setShowSearchDropdown(false); // Close dropdown immediately
  
    navigate(url + `/${result.route_key}`); // Navigate to the selected result
    setSearch(""); // Clear search input
  };
  

  // Close search dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  // Close search dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Close search dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Close search dropdown when clicking outside, but not navbar dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown3(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown3(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const toggleOldCareDropdown = () => {
    setShowOldCareDropdown(prevState => !prevState);
    setShowHipProDropdown(false); // Reset "Hip pro+" dropdown when Old Care is clicked
  };
  // Modify the toggleDropdown function to handle mobile dropdown
  const toggleDropdown = (isMobile) => {
    if (isMobile) {
      // Toggle mobile dropdown visibility
      setShowMobileDropdown((prevState) => !prevState);
    } else {
      // Toggle desktop dropdown visibility
      setShowDropdown((prevState) => !prevState);
    }
  };

  const handleItemClick = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick = () => {
    setShowMobileDropdown(false);
    setShowMobileDevicesDropdown(false);
    setShowOldCareDropdown(false);
    setShowMobileAboutDropdown(false);
    setMobile(false); // Ensure mobile menu closes
    setIsMenuOpen(false);
  };
  

  const handletreat = (department) => async (e) => {
    e.preventDefault();

    try {
      const res = await ServiceCall.gett(
        `/rxone/fetch/all/treatment/departments/${department}`
      );
      if (res) {
        setDepartdata(res?.data?.departments || []);
      }
    } catch (err) {
      console.error("Error fetching department data:", err);
      setDepartdata([]);
    }
  };

  const isSpecialBackground = nav || isMobile;
  const [searchTerm, setSearchTerm] = useState("");

  // Function to toggle the mobile menu open/close
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const handleScroll = () => {
      lastScrollY = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          const shouldScroll = lastScrollY > 0;
          if (shouldScroll !== isScrolled) {
            setIsScrolled(shouldScroll);
          }
          ticking = false;
        });

        ticking = true;
      }
    };
    
   
    
    
  
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolled]);

  return (
    <header className={`header ${nav && "header__color"} ${islogin && "login__container"} ms-main-header`}>
      {islogin && <Login />}
      {/* First Row */}
      <div
        className="desktop-header"
        style={{
          backgroundColor: isScrolled ? "white" : "#009185", 
          transition: "background-color 0.3s ease",
        }}
      >
    <div className="navbar-row first-row">
    <Link className="navbar-brand" to="/">
      <img src={isScrolled || isSpecialBackground ? header__logo : header__logo1} alt="Logo" />
    </Link>

    <div className="nav-right">
      {/* Search Bar */}
      <div
        ref={searchRef}
        className="search-container"
        style={{
          position: "relative",
          zIndex: 1000,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <div
          className="search-wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            width: "100%",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          {/* Search Input */}
          <input
            type="text"
            className="search-input"
            placeholder="Dr., Hospital, Treatment"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setShowSearchDropdown(true)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && search.trim() !== "") {
                handleSearch(search);
              }
            }}
            style={{
              padding: "12px 50px 12px 16px",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "6px",
              outline: "none",
              fontSize: "16px",
              height: "46px",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            }}
          />

          {/* Search Button */}
          <button
  type="button"
  onClick={handleSearch} // Click starts search and shows loader
  style={{
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translateY(-50%)",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
    color: "#555",
    display: "flex",
    alignItems: "center",
  }}
>
  {loading ? <div className="spinner"></div> : "🔍"} 
</button>

        </div>

        {/* Search Dropdown */}
        {showSearchDropdown && searchResults.length > 0 && (
  <div
    className="dropdown"
    style={{
      position: "absolute",
      top: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      maxWidth: "700px",
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: "6px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 2000,
      maxHeight: "250px",
      overflowY: "auto",
      marginTop: "5px",
      pointerEvents: "auto",
    }}
    onMouseDown={(e) => e.stopPropagation()} // Prevent dropdown from closing when clicking inside
  >
    {loading ? (
      <div style={{ padding: "20px", textAlign: "center" }}>
        <div className="spinner"></div>
      </div>
    ) : searchResults.length > 0 ? (
      searchResults.map((result, index) => (
        <div
          key={index}
          className="dropdown-item"
          style={{
            padding: "12px",
            borderBottom: "1px solid #eee",
            cursor: "pointer",
            transition: "background 0.2s",
            color: "#333",
            fontSize: "15px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
          onClick={(e) => handleSelect(result, e)}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#f5f5f5")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
        >
          {/* Always show 🩺 for treatments */}
          {result.type === "treatment" ? (
            <span style={{ fontSize: "20px", marginRight: "10px" }}>🩺</span>
          ) : result.image_url && result.image_url.trim() !== "" ? (
            <img
              src={result.image_url}
              alt={result.name}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "10px",
                objectFit: "cover",
              }}
            />
          ) : (
            <span style={{ fontSize: "20px", marginRight: "10px" }}>
              {result.type === "hospital" ? "🏥" : "👨‍⚕️"}
            </span>
          )}

          {/* Show name without repetition */}
          <span>{result.name}</span>
        </div>
      ))
    ) : (
      <div style={{ padding: "12px", color: "#666", textAlign: "center" }}>
        No results found
      </div>
    )}
  </div>
)}

        
      </div>

      {/* Become Partner Button */}
      <NavLink className="ms-secondary-btn ms-header-btn" to="/joinus">
        Become Partner
      </NavLink>
    </div>
  </div>


      {/* Second Row */}
      <div
        className="navbar-row second-row"
        style={{
          backgroundColor: isScrolled ? "#009185" : "white",
          transition: "background-color 0.3s ease",
          height:"52px",
        }}
      >
        <nav className="nav-links">
        <li
  className="nav-item ms-line-slide"
  style={{
    display: "inline-flex", // Aligns items neatly
    alignItems: "center", // Centers content vertically
    marginTop: "-1px", // Reduced for a cleaner look
    padding: "5px 12px", // Adds slight padding for a compact look
  }}
>
  <a
    href="https://rxonecare.com/"
    className="nav-link"
    style={{
      fontSize: "14px", // Smaller font for a sleek look
      fontWeight: "500", // Slightly bold for clarity
      color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",
      whiteSpace: "nowrap", // Prevents text wrapping
      textDecoration: "none", // Removes underline
      padding: "6px 10px", // Adds some spacing inside
      borderRadius: "6px", // Soft rounded edges
      transition: "all 0.3s ease-in-out", // Smooth hover effect
     
    }}
    onMouseEnter={(e) => (e.target.style.opacity = "0.8")}
    onMouseLeave={(e) => (e.target.style.opacity = "1")}
  >
    For Providers
  </a>
</li>
<li
  className="nav-item ms-line-slide"
  style={{
    display: "inline-flex", // Keeps alignment consistent
    alignItems: "center", // Centers text vertically
    padding: "5px 10px", // Adds slight padding for better clickability
  }}
>
  <NavLink
    activeclassname="active"
    to="/treatment"
    style={{
      fontSize: "14px", // Slightly smaller for a sleek look
      fontWeight: "500", // Ensures readability
      color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",
      textDecoration: "none", // Removes underline
      transition: "color 0.3s ease-in-out", // Smooth color transition on scroll
    }}
  >
    Treatments
  </NavLink>
</li>

<li
  className="nav-item ms-line-slide"
  style={{
    display: "inline-flex", // Aligns items properly
    alignItems: "center",
    marginRight: "10px", // Adjusted spacing for better balance
    padding: "5px 10px",
  }}
>
  <NavLink
    activeclassname="active"
    to="/international-patients"
    style={{
      fontSize: "14px",
      fontWeight: "500",
      color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",
      whiteSpace: "nowrap", // Prevents line breaks
      textDecoration: "none",
      transition: "color 0.3s ease-in-out",
      marginTop: "-1px",
    }}
  >
    International Patients
  </NavLink>
</li>

          {/* Services Dropdown */}
          <ul className="nav-menu" style={{marginTop:"-2px"}}>
  {/* Services Dropdown */}
  <li className="nav-item" onMouseEnter={() => setShowDropdown("services")} onMouseLeave={() => setShowDropdown(null)}>
    <div className="nav-link"style={{ color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",marginLeft:"-15px"}}>Services</div>
    {showDropdown === "services" && (
      <ul className="dropdown-menu">
        {[
          { path: "/consultation", label: "Consultation" },
          { path: "/treatment-advice", label: "Treatment Advice" },
          { path: "/consultation", label: "Second Opinion" },
          { path: "/order-medicine-online-india", label: "Order Medicine" },
          { path: "/book-pathology-tests-india", label: "Order Test" },
          { path: "/medical-finance-loan-india", label: "Medical Loan" },
        ].map((item, index) => (
          <li key={index} onClick={() => setShowDropdown(null)}>
            <NavLink to={item.path} className="dropdown-link">
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    )}
  </li>

  {/* Devices Dropdown */}
  <li className="nav-item" onMouseEnter={() => setShowDropdown("devices")} onMouseLeave={() => setShowDropdown(null)}>
    <div className="nav-link"style={{ color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",}}>Devices</div>
    {showDropdown === "devices" && (
      <ul className="dropdown-menu">
        <li className="dropdown-item" onMouseEnter={() => setShowOldCareDropdown(true)} onMouseLeave={() => setShowOldCareDropdown(false)}>
          Elderly Care
          {showOldCareDropdown && (
            <ul className="sub-dropdown">
              <li onClick={() => setShowDropdown(null)}>
                <NavLink to="/devices/elderly-care/ripple-hippro" className="dropdown-link">
                  Hip pro+
                </NavLink>
              </li>
            </ul>
          )}
        </li>
      </ul>
    )}
  </li>

  {/* About Dropdown */}
  <li className="nav-item" onMouseEnter={() => setShowDropdown("about")} onMouseLeave={() => setShowDropdown(null)}>
    <div className="nav-link"style={{ color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",}}>About</div>
    {showDropdown === "about" && (
      <ul className="dropdown-menu">
        {[
          { path: "/aboutus", label: "About Us" },
          { path: "/blogs", label: "Blog" },
        ].map((item, index) => (
          <li key={index} onClick={() => setShowDropdown(null)}>
            <NavLink to={item.path} className="dropdown-link">
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    )}
  </li>
</ul>

<li
  className="nav-item ms-line-slide"
  style={{
    display: "inline-flex",
    alignItems: "center",
    marginTop: "-2px",
    padding: "5px 12px",
    marginLeft: "-4px",
  }}
>
  <a
    href="https://rxonecare.org/"
    className="nav-link"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      fontSize: "14px",
      fontWeight: "500",
      color: isScrolled || nav || isMobile ? "#fff" : "var(--ms-primary-color)",
      whiteSpace: "nowrap",
      textDecoration: "none",
      padding: "6px 10px",
      borderRadius: "6px",
      transition: "all 0.3s ease-in-out",
    }}
    onMouseEnter={(e) => (e.target.style.opacity = "0.8")}
    onMouseLeave={(e) => (e.target.style.opacity = "1")}
  >
    Care Foundation
  </a>
</li>

                    
          {/* Devices Dropdown */}
       

          {/* About Dropdown */}
        
        
          </nav>
          <li className="nav-item" style={{marginTop:"6px", marginRight:"-12px"}}>
  <NavLink
    className="ms-secondary-btn ms-header-btn"
    to="http://www.tosto.re/RxOne"
    target="_blank"
    rel="noopener noreferrer"
  >
    Download App
  </NavLink>
</li>


                  

        {/* Mobile Menu Icon */}
       
      </div>
      </div>
      {/* Mobile Menu Dropdown */}
      <div className={`mobile-header ${isScrolled || isMenuOpen ? "mobile-header__color" : ""}`}>
      {/* Top Row: Logo and Menu Toggle */}
      <div className="mobile-header__top">
        <div className="mobile-logo">
          <Link
            className="navbar-brand"
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "150px",
              transition: "all 0.1s ease-in-out",
            }}
          >
          <img
    src={isScrolled || isMenuOpen ? header__logo : header__logo1}
  alt="Logo"
  style={{
    width: "100%",
    height: "auto",
    maxWidth: "120px",
    transition: "all 0.1s ease-in-out",
  }}
/>

          </Link>
        </div>
        <div className="menu_toggle" onClick={toggleMenu}>
          {isMenuOpen ? <CloseIcon fontSize="medium" /> : <MenuIcon fontSize="medium"  />}
        </div>
      </div>

      {/* Dropdown Menu: Navigation Items & Download Button */}
      {isMenuOpen && (
        <div className="menu__toggle__content">
          <ul className="nav-list">
            {/* For Providers */}
                                             <li
                                  style={{
                                    listStylePosition: "outside",
                                    paddingLeft: "0",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <a
                                    href="https://rxonecare.com/"
                                    className="nav-link"
                                    style={{
                                      display: "inline-block",
                                      paddingLeft: "0",
                                      marginLeft: "2.5rem",
                                    }}
                                  >
                                    For Providers
                                  </a>
                                </li>
            
                                <li 
  style={{
    listStylePosition: "outside",
    paddingLeft: "0",
    marginLeft: "0",
  }} 
  onClick={handleMobileItemClick}
>
  <NavLink 
   className="nav-link"
    to="/international-patients"
    style={{
      display: "block",
      textDecoration: "none",
      padding: "8px 12px",
      color: "#333",
      fontSize: "16px",
      marginLeft:"28px"
    }}
  >
    International Patients
  </NavLink>
</li>
<li 
  style={{
    listStylePosition: "outside",
    paddingLeft: "0",
    marginLeft: "0",
  }} 
  onClick={handleMobileItemClick}
>
  <NavLink 
    activeclassname="active" 
    to="/treatment"
    style={{
      display: "block",
      textDecoration: "none",
      padding: "8px 12px",
      color: "#333",
      fontSize: "16px",
      marginLeft:"28px"
    }}
  >
    Treatments
  </NavLink>
</li>
<li>
  <div
    onClick={() => toggleDropdown(true)}
    className="dropdown-toggle"
    style={{
      fontSize: "17px",
      fontWeight: "600",
      marginRight: "20px",
      marginLeft: "21px",
      cursor: "pointer",
    }}
  >
    Services
  </div>
  {showMobileDropdown && (
    <ul className="mobile-dropdown-list">
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/consultation">Consultation</NavLink>
      </li>
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/treatment-advice">Treatment Advice</NavLink>
      </li>
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/consultation">Second Opinion</NavLink>
      </li>
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/order-medicine-online-india">Order Medicine</NavLink>
      </li>
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/book-pathology-tests-india">Order Test</NavLink>
      </li>
      <li className="mobile-dropdown-item" onClick={handleMobileItemClick}>
        <NavLink to="/medical-finance-loan-india">Medical Loan</NavLink>
      </li>
    </ul>
  )}
</li>


                                <li>
              <div
                onClick={() => setShowMobileDevicesDropdown((prev) => !prev)} // Toggle "Devices" dropdown visibility
                className="dropdown-toggle"
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  marginRight: "20px",
                  marginLeft: "22px",
                  cursor: "pointer",
                }}
              >
                Devices
              </div>
              {showMobileDevicesDropdown && (
                <ul className="mobile-dropdown-list" style={{ padding: "0", margin: "0" }}>
                  <li
                    className="mobile-dropdown-item"
                    onClick={() => setShowOldCareDropdown((prev) => !prev)} // Toggle "Old Care" dropdown on click
                    style={{ position: "relative" }} // Ensure the nested dropdown is positioned correctly
                  >
                    <div
                      style={{
                        textDecoration: "none",
                        color: "#000",
                        padding: "10px 20px",
                        display: "block",
                        cursor: "pointer",
                      }}
                    >
                      Elderly Care
                    </div>
                    {showOldCareDropdown && (
                      <ul
                        className="mobile-dropdown-list"
                        style={{
                          padding: "0",
                          margin: "0",
                          position: "absolute",
                          top: "0",
                          left: "0", // Position the dropdown directly below "Old Care"
                          backgroundColor: "#fff",
                          listStyle: "none",
                          padding: "10px 0",
                          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          zIndex: 101,
                        }}
                      >
                        <li
                          className="mobile-dropdown-item"
                          style={{ padding: "10px 10px" }}
                        >
                          <NavLink
                            to="/devices/elderly-care/ripple-hippro"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                            }}
                            activeStyle={{ color: "#007bff" }}
                            onClick={handleMobileItemClick} // Use handleMobileItemClick here
                          >
                            Hip pro+
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>
            
            
           

                                <li className="mobile-navbar">
                  {/* Other navigation items */}
                  <li>
              <div
                onClick={() => toggleMobileAboutDropdown(!showMobileAboutDropdown)}
                className="dropdown-toggle"
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  marginRight: "20px",
                  marginLeft: "22px",
                  cursor: "pointer",
                }}
              >
                About
              </div>
              {showMobileAboutDropdown && (
                <ul className="mobile-dropdown-list">
                  <li
                    className="mobile-dropdown-item"
                    onClick={handleMobileItemClick}
                  >
                    <NavLink
                      to="/aboutus"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                      activeStyle={{ color: "#007bff" }}
                    >
                      About Us
                    </NavLink>
                  </li>
                  <li
                    className="mobile-dropdown-item"
                    onClick={handleMobileItemClick}
                    style={{ marginBottom: "10px" }}
                  >
                    <NavLink
                      to="/blogs"
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                      activeStyle={{ color: "#007bff" }}
                    >
                      Blog
                    </NavLink>
                  </li>
                  {/* Add CareFoundation here */}
                 
                </ul>
              )}
            </li>
            
                  {/* Other dropdowns or navigation items */}
                </li>
                <li
  style={{
    listStylePosition: "outside",
    paddingLeft: "0",
    marginLeft: "0px",
  }}
>
  <a
    href="https://rxonecare.org/"
    className="nav-link"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: "inline-block",
      paddingLeft: "0",
      marginLeft: "2.5rem",
    }}
  >
    Care Foundation
  </a>
</li>

                                <li>
                                  <a
                                    style={{
                                      padding: "5px 30px",
                                      fontSize: "14px",
                                      display: "inline-block",
                                      marginLeft: "7px",
                                    }}
                                    className="ms-secondary-btn ms-header-btn"
                                    href="http://www.tosto.re/RxOne"
                                  >
                                    {swap}
                                  </a>
                                </li>
                              </ul>
        </div>
      )}

      {/* Bottom Row: Search Bar and Join Us Button */}
      <div className="mobile-header__bottom">
      <div className="search-container" 
    style={{ 
      position: "relative", 
      zIndex: 1000, 
      width: "100%", 
      display: "flex", 
      justifyContent: "center" 
    }} 
    ref={searchRef}
  >
    <div className="search-wrapper" 
      style={{ 
        display: "flex", 
        alignItems: "center", 
        position: "relative", 
        width: "100%", 
        maxWidth: "450px"
      }}
    >
      {/* Search Input */}
      <input
        type="text"
        className="search-input"
        placeholder="Hos, Doc, Treat."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{
          padding: "8px 14px",
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "6px",
          outline: "none",
          fontSize: "14px",
          height: "36px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}
      />

      {/* Search Button (Right Icon) */}
      <button onClick={handleSearch} className="search-button">
  {loading ? <div className="spinner"></div> : "🔍"}
</button>

    </div>

    {/* Search Dropdown */}
    {showSearchDropdown && searchResults.length > 0 && (
  <div
    className="dropdown"
    style={{
      position: "absolute",
      top: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      maxWidth: "750px",
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: "6px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 2000,
      maxHeight: "250px",
      overflowY: "auto",
      marginTop: "5px",
    }}
  >
    {searchResults.map((result, index) => (
      <div
        key={index}
        className="dropdown-item"
        style={{
          padding: "10px",
          borderBottom: "1px solid #eee",
          cursor: "pointer",
          transition: "background 0.2s",
          color: "#333",
          fontSize: "13px",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => handleSelect(result, e)}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#f5f5f5")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
      >
        {/* Always show emoji for treatments */}
        {result.type === "treatment" ? (
          <span style={{ fontSize: "20px", marginRight: "10px" }}>🩺</span>
        ) : result.image_url && result.image_url.trim() !== "" ? (
          <img
            src={result.image_url}
            alt={result.name}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
              objectFit: "cover",
            }}
          />
        ) : (
          <span style={{ fontSize: "20px", marginRight: "10px" }}>
            {result.type === "hospital" ? "🏥" : "👨‍⚕️"}
          </span>
        )}
        
        {/* Show Name Without Repetition */}
        <span>{result.name}</span>
      </div>
    ))}
  </div>
)}

  </div>

  <NavLink to="/joinus">
    <button
      className="ms-secondary-btn ms-header-btn"
      style={{
        padding: "2px 17px",
        borderRadius: "30px",
        marginBottom: "-6px", // Adjust this value if needed
        borderColor: "#019a9e"
      }}
    >
      Become Partner
    </button>
  </NavLink>




</div>
    </div>
    </header>
  );
};

export default Header2;